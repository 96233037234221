import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import { SectionWrapper } from "../common/Section";
import { useStaticQuery, graphql } from "gatsby";

const PressWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.oxBlood};
  max-height: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.white};
  overflow: hidden;
  padding-bottom: 100px;
`;

const PressContainer = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 40%;
  max-width: 1976px;

  ${media.tablet`
    margin-top: 30.7%;
  `}

  ${media.desktop`
    margin-top: 20.7%;
    margin-bottom: 10%;
  `}

  ${media.desktopMax`
    margin-top: 12.9%;
  `}
`;

const MainContent = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 25px;

  ${media.desktop`
    margin: 0 60px;
  `}
`;

const Entry = styled.article`
  margin-bottom: 30px;

  ${media.desktop`
    margin: 0 20px 85px 20px;
    flex: 0 0 calc(33.3% - 1rem);
  `}
`;

const EntryImage = styled.img`
  max-width: 100%;
`;

const EntryContent = styled.div`
  margin-bottom: 27px;

  ${media.desktop`
    margin-bottom: auto;
  `}
`;

const Title = styled.h3`
  font: normal 11px/13px "Calluna Regular";
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;

  ${media.desktop`
    margin-bottom: auto;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 2.5px;
  `}
`;

const ArticleDate = styled.p`
  font: normal 12px/14px "Calluna Italic";
  letter-spacing: 2.5px;
  margin-top: 0;

  ${media.desktop`
    margin-top: 10px;
    font-size: 15px;
    line-height: 18px;
  `}
`;

const ArticleInfo = styled.h3`
  font: normal 17px/23px "Saol Text Book Italic";
  letter-spacing: 2px;
  margin: 10px 0;

  ${media.desktop`
    font-size: 27px;
    line-height: 31px;
    margin-top: 22px;
    margin-bottom: 18px;
  `}
`;

const ArticleAuthor = styled.p`
  font: normal 12px/14px "Calluna Light";
  margin-top: 0;
  margin-bottom: 20px;

  ${media.desktop`
    margin-top: auto;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 35px;
  `}
`;

const ArticleLink = styled.a`
  font: normal 14px/12px "Calluna Regular";
  color: ${(props) => props.theme.white};
  text-decoration: none;

  ${media.desktop`
    font-size: 15px;
    line-height: 18px;
  `}
`;

const PressInquiries = styled.div`
  width: 90%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  text-align: center;
`;

const InquiriesText = styled.p`
  font: normal 12px/18px "Saol Text Book Italic";

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${media.desktop`
    font-size: 21px;
    line-height: 31px;

    br {
      display: none;
    }
  `}
`;

const Article = (props) => {
  const { photo, title, author, date, info, linkUrl } = props.item;
  const optimizedImage = `${photo.file.url}?w=640&q=90`;
  return (
    <Entry key={title}>
      <EntryImage src={optimizedImage} />
      <EntryContent>
        <Title>{title}</Title>
        <ArticleDate>Featured on: {date}</ArticleDate>
        <ArticleInfo
          dangerouslySetInnerHTML={{ __html: info.childMarkdownRemark.html }}
        />
        <ArticleAuthor>- {author}</ArticleAuthor>
        <ArticleLink href={linkUrl} target="_blank">READ MORE</ArticleLink>
      </EntryContent>
    </Entry>
  );
};

export const PressArticles = () => {
  const data = useStaticQuery(GetPressQuery);
  const pressContent = data.allContentfulPressPage.edges[0].node;
  const pressItems = pressContent.pressArticles;
  return (
    <PressWrapper>
      <PressContainer>
        <MainContent>
          {pressItems.map((item, i) => (
            <Article item={item} key={item.title + i} />
          ))}
        </MainContent>
      </PressContainer>
      <PressInquiries>
        <InquiriesText>
          For Press Inquiries please contact
          <br /> <a href="mailto:1228madison@camronpr.com">1228madison@camronpr.com</a>
        </InquiriesText>
      </PressInquiries>
    </PressWrapper>
  );
};

export const GetPressQuery = graphql`
  query GetPressPageContent {
    allContentfulPressPage {
      edges {
        node {
          pressArticles {
            title
            author
            date
            info {
              childMarkdownRemark {
                html
              }
            }
            photo {
              file {
                url
              }
            }
            linkUrl
          }
        }
      }
    }
  }
`;
