import React, { useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import media from "../../styles/media";
import Arrow from "../../images/WhiteArrow.svg";
import blackArrow from "../../images/BlackArrow.svg";

export const SliderWrapper = styled.section`
  position: relative;
  min-width: 0;
  height: 60vh;
  max-width: 100%;
  padding-top: 50px;
  background-color: ${({ bgColor }) => bgColor};

  ${media.tablet`
    height: 95vh;
  `}

  .slick-slide {
    img {
      height: 60vh;

      ${media.tablet`
        height: 95vh;
        max-width: 100vw;
        max-height: 100%;
      `}
    }
  }

  .slick-list,
  .slider,
  .slick-track {
    height: 60vh;

    ${media.tablet`
      height: 95vh;
    `}
  }
`;

export const Slide = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 60vh;
  width: 100vw;
  height: 60vh;

  ${media.tablet`
    height: 95vh;
    max-height: 95vh;
  `}
`;

export const DotsWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 0;
  list-style: none;

  li {
    margin: 0 1.5px;

    button {
      padding: 4.5px;
    }

    button:before {
      display: none;
    }

    .dot {
      position: absolute;
      height: 2px;
      width: 2px;
      bottom: 42px;
      border: 1px solid white;
      border-radius: 6px;
      color: white;
    }

    &.slick-active .dot {
      background-color: white;
    }
  }
`;

export const HeroImage = styled.img`
  position: relative;
  z-index: 3;
  align-items: center;
  background-size: cover;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  height: 60vh;
  max-height: 60vh;
  object-fit: cover;
  object-position: center center;

  ${media.tablet`
    height: 95vh;
    max-height: 95vh;
  `}
`;

export const ImageCaption = styled.figcaption`
  ${media.tablet`
    display: flex;
    position: absolute;
    z-index: 5;
    align-items: flex-end;
    bottom: 47px;
    font: normal 14px "Calluna Italic";
    color: ${(props) => (props.lightCaption ? "white" : "black")};
    margin-left: 29px;
  `}
`;

export const SliderWrapperCaption = styled.figcaption`
  display: flex;
  position: absolute;
  z-index: 5;
  left: 1.8%;
  top: 18px;
  align-items: flex-end;
  font: normal 54px/53px "Saol Text Book";
  padding-bottom: 40px;
  color: black;
  letter-spacing: 1px;

  ${media.desktop`
    top: 28px;
    right: 60px;
    left: auto;
  `}
`;

const SliderWrapperCaptionWhite = styled(SliderWrapperCaption)`
  color: white;
`;

const ArrowNext = styled.div`
  position: absolute;
  z-index: 10;
  top: 17.5%;
  left: 18px;
  cursor: pointer;

  ${media.desktop`
    left: auto;
    right: 200px;
    top: 16%;
  `}
`;

export function HeroCarousel({
  slideOne,
  slideOneCaption,
  slideTwo,
  slideTwoCaption,
  slideThree,
  slideThreeCaption,
  slideFour,
  slideFourCaption,
  light,
  lightCaption,
  bgColor,
}) {
  const sliderRef = useRef();

  const settings = {
    dots: true,
    draggable: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapper>{dots}</DotsWrapper>,
    customPaging: () => <button className='dot' />,
    nextArrow: <NextArrow />,
  };

  function NextArrow() {
    return (
      <ArrowNext
        onClick={() => {
          sliderRef.current.slickNext();
        }}
      >
        <img src={light ? Arrow : blackArrow} />
      </ArrowNext>
    );
  }

  return (
    <SliderWrapper bgColor={bgColor}>
      {light ? (
        <SliderWrapperCaptionWhite>
          Previous
          <br /> Work
        </SliderWrapperCaptionWhite>
      ) : (
        <SliderWrapperCaption>
          Previous
          <br /> Work
        </SliderWrapperCaption>
      )}
      <Slider {...settings} ref={sliderRef}>
        <Slide>
          <HeroImage src={slideOne} />
          <ImageCaption lightCaption={lightCaption}>
            {slideOneCaption}
          </ImageCaption>
        </Slide>
        <Slide>
          <HeroImage src={slideTwo} />
          <ImageCaption>{slideTwoCaption}</ImageCaption>
        </Slide>
        <Slide>
          <HeroImage src={slideThree} />
          <ImageCaption lightCaption={lightCaption}>
            {slideThreeCaption}
          </ImageCaption>
        </Slide>
        <Slide>
          <HeroImage src={slideFour} />
          <ImageCaption lightCaption={lightCaption}>
            {slideFourCaption}
          </ImageCaption>
        </Slide>
      </Slider>
    </SliderWrapper>
  );
}
