import React from "react";
import styled, { css } from "styled-components";
import media from "../../styles/media";
import { SectionWrapper, GutterAddresses } from "../common/Section";

export const BioSplitWrapper = styled(SectionWrapper)`
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) =>
    props.light ? props.theme.offWhite : props.theme.merino};
  color: ${(props) => props.theme.oxBlood};

  ${media.mobileSmall`
    height: 100%;
    padding: 160px 0 120px 0;
    max-height: 100%;
    min-height: 100vh;
  `}

  ${(props) =>
    props.isFirst &&
    css`
      margin-top: -79px;

      ${media.tablet`
        padding: 30px 0 0 0;
      `}

      ${media.desktop`
        min-height: 768px;
        height: inherit;

        max-height: 100vh;
      `}

      ${media.desktopLarge`
        min-height: 1028px;
      `}
    `}
`;

export const BioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${media.tablet`
    padding: 115px 0 59px 0;
  `}

  ${media.desktop`
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0;
  `};
`;

const BioImage = styled.img`
  display: none;

  ${media.desktop`
    display: block;
    height: 553px;
    width: 434px;
  `}

  ${media.desktopLarge`
    display: block;
    height: 745px;
    width: 587px;
  `}
`;

const BioImageMobile = styled.img`
  display: block;
  width: 351px;

  ${media.desktop`
    display: none;
  `}
`;

const TextContainer = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 553px;
    width: 434px;
  `}

  ${media.desktopLarge`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 745px;
    width: 587px;
  `}
`;

const TextContainerMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  width: 351px;

  ${media.desktop`
    display:none;
  `}
`;

const Title = styled.h3`
  font-family: "Saol Text Book";
  font-size: ${(props) => (props.isMobileTitle ? "28px" : "38px")};
  font-weight: normal;
  margin: ${(props) => (props.isMobileTitle ? "0 0 50px 0;" : "0 0 30px 0;")};
  line-height: ${(props) => (props.isMobileTitle ? "33px" : "42px")};
  text-align: left;

  ${media.desktop`
    margin: 0px 0 -7px 0;
    font-size: 14px;
    text-align: center;
  `}

  ${media.desktopLarge`
    font-family: "Calluna Semibold";
    font-size: 18px;
    margin: 0 0 10px 0;
    line-height: 42px;
    text-align: center;
    white-space: pre;
  `}
`;

const TextBlock = styled.div`
  font-family: "Calluna Regular";
  font-size: 13px;
  margin: 30px 30px 0 30px;
  line-height: 25px;

  ${media.desktop`
    margin: 0 65px;
    text-align: center;
    line-height: 20px;
    letter-spacing: .2px;
    font-size: 12px;
  `}

  ${media.desktopLarge`
    font-size: 15px;
    text-align: center;
  `}

  ${media.desktopLarge`
    margin: -5px 110px 0 110px;
  `}
`;

export const BioSplit = ({
  desktopImage,
  mobileImage,
  description,
  title,
  light,
  mobileBio,
  isFirst,
  isMobileTitle,
  mobileBuildingTitle,
  desktopBuildingTitle,
}) => (
  <BioSplitWrapper isFirst={isFirst} light={light}>
    <BioContainer isFirst={isFirst}>
      <BioImage src={desktopImage} alt='image of bio subject' />
      <BioImageMobile src={mobileImage} alt='mobile image of bio subject' />
      <TextContainer>
        <TextBlock>
          {title && <Title>{title}</Title>}
          {desktopBuildingTitle && <Title>{desktopBuildingTitle}</Title>}

          <span dangerouslySetInnerHTML={{ __html: `${description}` }} />
        </TextBlock>
      </TextContainer>
      <TextContainerMobile>
        <TextBlock>
          {title && <Title>{title}</Title>}
          {mobileBuildingTitle && (
            <Title
              isMobileTitle={isMobileTitle}
              dangerouslySetInnerHTML={{
                __html: `${mobileBuildingTitle}`,
              }}
            />
          )}

          <span dangerouslySetInnerHTML={{ __html: `${mobileBio}` }} />
        </TextBlock>
      </TextContainerMobile>
    </BioContainer>
    <GutterAddresses />
  </BioSplitWrapper>
);
