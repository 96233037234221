import React from "react";
import { MadisonLogo } from "./MadisonLogo";
import styled, { ThemeProvider, keyframes } from "styled-components";
import theme from "../../../styles/theme";
import media from "../../../styles/media";
import { MadisonMenu } from "./Menu";
import { Link } from "gatsby";
import Headroom from "react-headroom";
import { PageTitle } from "./PageTitle";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const ContactWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.offColor};

  ${media.desktop`
    left: auto;
    transform: none;
    right: 104px;
  `};
`;

const Contact = styled(Link)`
  font-family: "Calluna Italic";
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
`;

const HeaderWrapper = styled(Headroom)`
  position: ${({ menucolor }) => (menucolor ? "relative" : "absolute")};

  /* If this is not set, then the menu is not clicakble over videos */
  z-index: 20;

  top: 0;
  left: 0;
  width: 100%;
  height: 79px;
  background-color: ${({ theme }) => theme.backgroundColor};

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 79px;
  }

  .headroom--unfixed {
    position: relative;
    transform: translateY(0);

    background-color: transparent;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;

    ${Contact} {
      color: ${({ theme }) => theme.offColor};
    }
  }

  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }

  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);

    ${ContactWrapper} {
      color: ${({ theme }) => theme.black};
      border-bottom: 1px solid ${({ theme }) => theme.offColor};
    }

    .page-title {
      opacity: 0;
    }
  }

  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
    background-color: ${({ theme }) => theme.white};

    ${ContactWrapper} {
      border-bottom: 1px solid ${({ theme }) => theme.black};
    }

    h1,
    ${Contact} {
      color: ${({ theme }) => theme.black};
    }

    .bm-burger-bars {
      background-color: ${({ theme }) => theme.black} !important;
    }

    .logo {
      filter: invert(0) !important;
    }

    .page-title {
      opacity: 0;
    }
  }
`;

export const Header = ({ menucolor, onLogoClick, pageTitle }) => {
  const darkTheme = {
    ...theme,
    backgroundColor: theme.oxBlood,
    offColor: pageTitle === 'Press' ? theme.black : theme.white
  };

  const lightTheme = {
    ...theme,
    backgroundColor: menucolor,
    offColor: theme.black
  };

  const headerTheme = menucolor === theme.oxBlood ? darkTheme : lightTheme;

  return (
    <>
      <ThemeProvider theme={headerTheme}>
        <HeaderWrapper disableInlineStyles menucolor={menucolor} wrapperStyle={{ background: pageTitle == 'Architecture' ? '#ffffff' : 'transparent' }}>
          {pageTitle && (
            <PageTitle pageTitle={pageTitle} className="page-title" />
          )}
          <ContactWrapper>
            <Contact to="/contact">Contact</Contact>
          </ContactWrapper>

          <MadisonLogo onLogoClick={onLogoClick} />
          <MadisonMenu />
        </HeaderWrapper>
      </ThemeProvider>
    </>
  );
};
