import React from "react";
import styled from "styled-components";
import media from "../../styles/media";

const VideoPlayer = styled.video`
  display: block;
  width: 100vw;
  object-fit: cover;
  background-color: #e1dace;
  height: 100vw;

  ${media.desktop`
    height: calc(100vh - 79px);
  `};
`;

export function Video({ children, poster }) {
  return (
    <VideoPlayer controls poster={poster}>
      {children}
    </VideoPlayer>
  );
}
