import React, { useState } from "react";
import styled from "styled-components";
import media from "../../styles/media";
import { SectionWrapper } from "../common/Section";
import {
  teamInfo,
  TeamContainer,
  TeamBox,
  TitleContainer,
  Title,
  TextBox,
} from "./TeamInfo";

const TeamWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.merino};
  justify-content: center;
  align-items: center;
  padding-bottom: 20%;

  ${media.desktop`
    padding-bottom: 0;
  `}
`;

const RadioWrapper = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    position: absolute;
    margin-top: 230px;
    padding-left: 40px;
    z-index: 3;
    bottom: 52%;
    left: 48px;
    transform: translate(-50%, 50%) rotate(90deg);
  `}

  ${media.desktopLarge`
    left: 55px;
    bottom: 50%
  `}
`;

const RadioButtonContainer = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 22px;
  padding-right: 0px;

  input {
    display: none;
  }

  .circle {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid black;
  }

  .circle.active {
    background-color: black;
  }

  input:checked + .circle:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
`;

export function Teams() {
  const [teamIndex, setTeamIndex] = useState("Architecture");

  return (
    <TeamWrapper>
      <RadioWrapper role='radiogroup'>
        {Object.keys(teamInfo).map((key) => (
          <RadioButtonContainer htmlFor={`radio-${key}`} key={key}>
            <input id={`radio-${key}`} type='radio' name='radio-button' />
            <span
              role='button'
              tabIndex={key}
              onClick={() => setTeamIndex(key)}
              className={`circle ${teamIndex === key ? "active" : null}`}
              onKeyDown={() => setTeamIndex(key)}
            ></span>
          </RadioButtonContainer>
        ))}
      </RadioWrapper>
      <TeamContainer>
        <TeamBox>
          <TitleContainer>
            {Object.keys(teamInfo).map((key) => (
              <Title
                onClick={() => setTeamIndex(key)}
                onKeyDown={() => setTeamIndex(key)}
                className={teamIndex === key ? "active" : null}
                key={key}
              >
                {key}
              </Title>
            ))}
          </TitleContainer>
        </TeamBox>
        <TextBox>{teamInfo[teamIndex]}</TextBox>
      </TeamContainer>
    </TeamWrapper>
  );
}
