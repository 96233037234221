import React from "react";
import media from "../../styles/media";
import styled from "styled-components";
import penthouseKitch from "../../images/PHKitchenPT.jpg";
import { Desktop, Tablet } from "../common/media";
import { SectionWrapper } from "../common/Section";

const KitchenWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.offWhite};
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const HeroWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  padding: 0 30px;
`;

const HeroAssetDesktopImage = styled.img`
  height: 95vh;

  ${media.desktop`
    height: 92.5vh;
    width: 100%;
  `}
`;

const TabletImage = styled.img`
  max-height: 90vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ImageCaption = styled.figcaption`
  display: none;

  ${media.desktop`
    display: block;
    position: absolute;
    z-index: 3;
    font: normal 14px "Calluna Italic";
    left: 38px;
    bottom: 46px;
    color: ${(props) => props.theme.white};
  `}

  ${media.desktopLarge`
    bottom: 46px;
    left: 40px;
  `}
`;

export const PenthouseKitchenModule = () => (
  <KitchenWrapper>
    <Desktop>
      <HeroWrapper>
        <HeroAssetDesktopImage src={penthouseKitch} />
      </HeroWrapper>
      <ImageCaption>Penthouse Kitchen with Private Terrace</ImageCaption>
    </Desktop>
    <Tablet>
      <TabletImage src={penthouseKitch} />
    </Tablet>
  </KitchenWrapper>
);
