import React from "react";
import styled, { css } from "styled-components";
import { Desktop, Tablet } from "./common/media";
import { SectionWrapper } from "../components/common/Section";

export const HeroWrapper = styled(SectionWrapper)`
  display: block;
  max-width: 100%;
  max-height: none;
  width: 100%;
  height: auto;
`;

export const HeroAssetDesktopImage = styled.img`
  display: block;
  height: auto;
  max-height: none;
  max-width: 100%;
  width: 100%;
`;

const HeroAssetMobileImage = styled(HeroAssetDesktopImage)`
  display: block;
  width: 100%;
`;

export const HeroLimitHeight = ({
  heroImageDesktop,
  heroImageMobile,
  caption,
}) => (
  <HeroWrapper>
    <Desktop>
      <HeroAssetDesktopImage alt={caption} src={heroImageDesktop} />
    </Desktop>
    <Tablet>
      <HeroAssetMobileImage
        alt={caption}
        src={heroImageMobile}
      />
    </Tablet>
  </HeroWrapper>
);
