import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import { PageTitleCaption } from "../../common/Header/PageTitle";
import media from "../../../styles/media";
import logoImg from "../../../images/Logo.svg";
import {
  LegalWrapper,
  LegalContainer,
  LegalBox,
  TitleContainer,
  Title,
  TextContainer,
  LogoSmall,
  Close,
  CloseImage,
} from "./LegalModal";
import blackx from "../../../images/blackx.svg";

const PrivacyWrapper = styled(LegalWrapper)`
  background-color: ${(props) => props.theme.offWhite};
  color: ${(props) => props.theme.black};
`;

const PrivacyBox = styled(LegalBox)`
  ${media.desktop`
    border-right: 1px solid black;
  `}
`;

const PrivacyTitleContainer = styled(TitleContainer)`
  border-bottom: 1px solid black;

  ${media.desktop`
    left: 19.5%;
  `}

  ${media.desktop`
    border-bottom: none;
  `}
`;

const PrivacyTitle = styled(Title)`
  letter-spacing: 2.3px;

  ${media.desktop`
    letter-spacing: 3.2px;
  `}
`;

const TextBox = styled(LegalBox)`
  border-right: none;
  overflow-y: scroll;
  height: 250px;
  margin-top: 20px;

  ${media.tablet`
    border-right: none;
    overflow-y: scroll;
    height: 369px;
  `}
`;

const PrivacyTextContainer = styled(TextContainer)`
  p {
    margin-top: 5px;

    ${media.desktop`
      margin-top: auto;
    `}

    a {
      color: black;
    }
  }
`;

const PrivacyFont = styled.span`
  font-weight: 700;
`;

const PageTitlePrivacy = styled(PageTitleCaption)`
  color: black;
  ${media.desktop`
    left: 102px;
  `}
`;

const pageCaption = "Legal and Privacy";

export const PrivacyModal = ({ closeModal, privacyText }) => (
  <PrivacyWrapper>
    <PageTitlePrivacy>{pageCaption}</PageTitlePrivacy>
    {closeModal && (
       <Close onClick={closeModal}>
         <CloseImage src={blackx} alt='Close' />
       </Close>
     )}

    <Link to='/'>
      <LogoSmall alt='1228 Madison' src={logoImg} />
    </Link>
    
    <LegalContainer>
      <PrivacyBox>
        <PrivacyTitleContainer>
          <PrivacyTitle>Privacy</PrivacyTitle>
        </PrivacyTitleContainer>
      </PrivacyBox>
      <TextBox>
        <PrivacyTextContainer
          dangerouslySetInnerHTML={{ __html: privacyText }}
        />
      </TextBox>
    </LegalContainer>
  </PrivacyWrapper>
);
