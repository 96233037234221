import React, { useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import media from '../../styles/media';
import materialOne from '../../images/ArchitectMaterialImage.jpg';
import designMaterialOne from '../../images/DesignMaterialOne.jpg';
import { GutterAddresses } from '../common/Section';
import WhiteArrowPrevious from '../../images/WhiteArrowPrevious.svg';
import WhiteArrowPreviousMobile from '../../images/WhiteArrowPreviousMobile.svg';
import WhiteArrowNext from '../../images/WhiteArrowNext.svg';
import WhiteArrowNextMobile from '../../images/WhiteArrowNextMobile.svg';
import {
  DotsWrapperMaterial,
  ArchitectWrapper,
  TitleWrapper,
  SliderContainer,
  Slide,
  CarouselTitle,
  ArrowNext,
  ArrowPrevious,
  ImageCaption,
  ImageDesktop,
  ImageMobile,
} from './ArchitectMaterialCarousel';

const DesignerWrapper = styled(ArchitectWrapper)`
  position: relative;
  background-color: ${(props) => props.theme.merino};
`;

const DesignSliderContainer = styled(SliderContainer)`
  width: 289px;
  height: 63vh;

  ${media.tablet`
    width: 469px;
    height: 625px;
  `}

  .slick-slide {
    img {
      max-height: 63vh;

      ${media.tablet`
        max-height: 80vh;
        height: 625px;
      `}
    }
  }

  .slick-list,
  .slider,
  .slick-track {
    ${media.tablet`
      max-height: 80vh;
    `}

    @media (min-width: 1700px) {
      max-height: 70vh;
    }

    @media (min-width: 2000px) {
      max-height: 50vh;
    }
  }
`;

const DesignCarouselTitle = styled(CarouselTitle)`
  color: ${(props) => props.theme.white};

  top: 0;
  margin: 0 auto;
  transform: translate(-50%, -50%);

  ${media.mobileSmall`
    left: 50%;
    letter-spacing: 2.6px;
    font-size: 38px;
  `}

  ${media.tablet`
    line-height: 73px;
    font-size: 48px;
  `}
`;

const DesignerImageCaption = styled(ImageCaption)`
  bottom: 22.5%;
`;

export const DotsWrapperDesign = styled(DotsWrapperMaterial)`
  li {
    .dot {
      bottom: 125px;

      ${media.tablet`
        color: white;
        bottom: -30px;
        border: 1px solid white;
      `}

      ${media.desktopLarge`
        left: 5px;
      `}

      @media(min-height: 900px) {
        bottom: 100px;
      }
    }

    &.slick-active .dot {
      ${media.tablet`
        background-color: white;
      `}
    }
  }
`;

const ArrowPreviousDesign = styled(ArrowPrevious)`
  top: 36.1%;
  right: 93%;

  ${media.tablet`
    top: 45.5%;
  `}
`;

const ArrowNextDesign = styled(ArrowNext)`
  top: 36%;
  left: 93%;

  ${media.tablet`
    top: 45.5%;
    left: 92.5%;
  `}
`;

export function DesignerMaterialCarousel() {
  const slideRef = useRef();

  const settings = {
    dots: true,
    draggable: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapperDesign>{dots}</DotsWrapperDesign>,
    customPaging: () => <button className='dot' />,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  function PrevArrow() {
    return (
      <ArrowPreviousDesign
        onClick={() => {
          slideRef.current.slickPrev();
        }}
      >
        <ImageDesktop src={WhiteArrowPrevious} />
        <ImageMobile src={WhiteArrowPreviousMobile} />
      </ArrowPreviousDesign>
    );
  }

  function NextArrow() {
    return (
      <ArrowNextDesign
        onClick={() => {
          slideRef.current.slickNext();
        }}
      >
        <ImageDesktop src={WhiteArrowNext} />
        <ImageMobile src={WhiteArrowNextMobile} />
      </ArrowNextDesign>
    );
  }

  return (
    <DesignerWrapper>
      <TitleWrapper>
        <DesignCarouselTitle>Materials</DesignCarouselTitle>
        <DesignSliderContainer>
          <Slider {...settings} ref={slideRef}>
            <Slide>
              <img src={designMaterialOne} alt='building materials' />
              <DesignerImageCaption>
                Tk material used with TK metal on the building&nbsp;façade
              </DesignerImageCaption>
            </Slide>
            <Slide>
              <img src={materialOne} alt='building materials' />
            </Slide>
            <Slide>
              <img src={designMaterialOne} alt='building materials' />
            </Slide>
            <Slide>
              <img src={materialOne} alt='building materials' />
            </Slide>
          </Slider>
        </DesignSliderContainer>
      </TitleWrapper>
      <GutterAddresses />
    </DesignerWrapper>
  );
}
