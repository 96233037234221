import React from "react";
import styled from "styled-components";
import { DesktopSmall } from "./media";
import media from "../../styles/media";

export const SectionWrapper = styled.section`
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
`;

export const Address = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  font-family: "Calluna light";
  font-size: 16px;
  justify-content: center;
  font-weight: normal;
  left: 50px;
  transform: translate(-50%, 0) rotate(-90deg);

  ${media.desktopLarge`
    left: 67px;
  `}

  address {
    font-style: normal;
  }
`;

export const AddressRight = styled(Address)`
  left: auto;
  right: -92px;

  ${media.desktopLarge`
    left: auto;
    right: -85px;
  `}

  transform: translate(-50%, 0) rotate(90deg);
`;

export const GutterAddresses = () => (
  <DesktopSmall>
    <Address>
      <address aria-hidden='true'>1228 Madison Avenue</address>
    </Address>
    <AddressRight>
      <address aria-hidden='true'>1228 Madison Avenue</address>
    </AddressRight>
  </DesktopSmall>
);
