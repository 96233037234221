import React, { useRef } from "react";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import media from "../../styles/media";
import kitchenOne from "../../images/k1.jpg";
import kitchenTwo from "../../images/k2.jpg";
import kitchenThree from "../../images/k3.jpg";
import whiteArrowNext from "../../images/CarouselWhiteArrowNext.svg";
import whiteArrowPrevious from "../../images/CarouselWhiteArrowPrevious.svg";
import WhiteArrowPreviousMobile from "../../images/WhiteArrowPreviousMobile.svg";
import WhiteArrowNextMobile from "../../images/WhiteArrowNextMobile.svg";
import { DotsWrapper } from "./HeroCarousel";
import { SectionWrapper, GutterAddresses } from "../common/Section";

const SliderWrapper = styled(SectionWrapper)`
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.merino};
`;

const SliderContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 0;
  height: 71vh;
  max-width: 100%;
  max-height: 70vh;
  margin: 0 auto;
  width: 75vw;
  padding: 0 26px;

  ${media.tablet`
    max-height: 75vh;
    height: 75vh;
    padding: 0 130px;
  `}

  ${media.desktopMax`
    padding: 0 250px;
    width: 1257px;
  `}

  .slick-slide {
    min-width: 0;

    img {
      height: 100%;
      width: 100%;
      max-width: 100vw;
      max-height: 71vh;
      min-width: 0;
      object-fit: cover;

      ${media.tablet`
        max-height: 75vh;
      `}
    }
  }

  .slick-list,
  .slider,
  .slick-track {
    height: 85vh;
    min-width: 0;
    max-height: 85vh;

    ${media.tablet`
      height: 75vh;
      max-height: 75vh;
    `}
  }
`;

const Slide = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  min-width: 0;
`;

const GutterAddressCarousel = styled.div`
  display: none;

  @media (min-width: 1530px) {
    display: block;
  }
`;

export const DotsWrapperKitchen = styled(DotsWrapper)`
  bottom: auto;

  li {
    .dot {
      top: 8px;

      ${media.tablet`
        top: 40px;
      `}

      ${media.desktop`
        top: 20px;
      `}
    }
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  margin-top: -25%;

  ${media.tablet`
    margin-top: 0;
  `}
`;

const CarouselTitle = styled.h1`
  display: flex;
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);z-index: 5;
  text-align: center;
  color: ${(props) => props.theme.white};
  letter-spacing: 1.6px;
  font-family: "Saol Text Book";
  font-weight: normal;

  ${media.mobileSmall`
    line-height: 39px;
    font-size: 38px;
  `}

  ${media.mobileMedium`
    font-size: 40px;
  `}

  ${media.tablet`
    font-size: 47px;
    line-height: 71px;
    letter-spacing: 2.2px;
    white-space: pre;
  `}

`;

const CaptionStyles = css`
  display: flex;
  position: absolute;
  z-index: 5;
  font: normal 12px/11px "Calluna Italic";
`;

const ImageCaption = styled.figcaption`
  ${CaptionStyles}
  bottom: 16%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  width: 100%;

  ${media.tablet`
    display: block;
    left: auto;
    transform: translate(0);
    right: 10px;
    bottom: 28%;
    text-align: left;
    color: white;
    width: 25%;
  `}
`;

export const ArrowPrevious = styled.div`
  position: absolute;
  z-index: 10;
  top: 40.7%;
  right: 90%;

  ${media.mobileLarge`
    top: 47.9%;
    right: 92.5%;
  `}

  ${media.desktop`
    right: 97%;
  `}
`;

export const ArrowNext = styled.div`
  position: absolute;
  z-index: 10;
  top: 40.6%;
  left: 90%;

  ${media.mobileLarge`
    top: 47.9%;
    left: 92.5%;
  `}

  ${media.desktop`
    left: 96.5%;
  `}
`;

export const ImageDesktop = styled.img`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

export const ImageMobile = styled.img`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

export function InteriorKitchenCarousel() {
  const slideRef = useRef();

  const settings = {
    dots: true,
    draggable: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapperKitchen>{dots}</DotsWrapperKitchen>,
    customPaging: () => <button className='dot' />,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  function PrevArrow() {
    return (
      <ArrowPrevious
        onClick={() => {
          slideRef.current.slickPrev();
        }}
      >
        <ImageDesktop src={whiteArrowPrevious} alt='previous' />
        <ImageMobile src={WhiteArrowPreviousMobile} alt='previous' />
      </ArrowPrevious>
    );
  }

  function NextArrow() {
    return (
      <ArrowNext
        onClick={() => {
          slideRef.current.slickNext();
        }}
      >
        <ImageDesktop src={whiteArrowNext} alt='next' />
        <ImageMobile src={WhiteArrowNextMobile} alt='next' />
      </ArrowNext>
    );
  }

  return (
    <SliderWrapper>
      <TitleWrapper>
        <CarouselTitle>Kitchens</CarouselTitle>
        <SliderContainer>
          <Slider {...settings} ref={slideRef}>
            <Slide>
              <img src={kitchenOne} alt='view of kitchen and dining table' />
              <ImageCaption>
                Warm enough for a Sunday brunch, but stylish enough to serve as home 
                base for the most lavish party, the kitchens at 1228 Madison Avenue are 
                designed to be both sumptuous and functional, with custom matte lacquer 
                cabinetry, Calacatta Estremoz marble, made to order Lacanche ranges, and oversized 
                windows that afford some residence exemplary views of Central Park.
              </ImageCaption>
            </Slide>
            <Slide>
              <img src={kitchenTwo} alt='view of kitchen island' />
            </Slide>
            <Slide>
              <img
                src={kitchenThree}
                alt='view of kitchen island and seating'
              />
            </Slide>
          </Slider>
        </SliderContainer>
      </TitleWrapper>
      <GutterAddressCarousel>
        <GutterAddresses />
      </GutterAddressCarousel>
    </SliderWrapper>
  );
}
