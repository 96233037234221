import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import media from "../styles/media";
import arrowDown from "../images/arrow.svg";
import { MadisonLogo } from "./common/Header/MadisonLogo";
import { SectionWrapper } from "../components/common/Section";
import ProgressiveImage from "react-progressive-graceful-image"
import { Desktop, Tablet } from "./common/media";
import { Link } from "gatsby";

const expand = keyframes`
  0% {
    height: 100vh;
  }

  100% {
    height: 0px;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const reshow = keyframes`
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 100vh;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Container = styled.section`
  position: relative;
  z-index: 2;

  overflow: hidden;

  &.hidden,
  &.server-refresh {
    opacity: 0;
  }

  &.slide-up {
    animation: ${expand} 1s;
    animation-fill-mode: forwards;

    .arrow-wrapper,
    .quote-text-wrapper,
    .logo {
      opacity: 1;
      animation: ${fadeOut} 0.5s;
      animation-fill-mode: forwards;
    }
  }

  &.slide-down {
    animation: ${reshow} 1s;
    animation-fill-mode: forwards;

    .arrow-wrapper,
    .quote-text-wrapper {
      opacity: 0;
      animation: ${fadeIn} 2s;
      animation-fill-mode: forwards;
    }
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  p {
    font: 15px "Calluna light";
    color: white;
  }

  :hover {
    cursor: pointer;
  }

  text-align: center;

  -webkit-animation: ${fadeIn} 6s;
  -moz-animation: ${fadeIn} 6s;
  -ms-animation: ${fadeIn} 6s;
  -o-animation: ${fadeIn} 6s;
  animation: ${fadeIn} 6s;

`;

const Arrow = styled.img``;

const Video = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-color: #e1dace;

  &.large {
    display: none;
    ${media.tablet`
      display: block;
    `}
  }

  &.small {
    ${media.tablet`
      display: none;
    `}
  }
`;

export const HeroWrapper = styled(SectionWrapper)`
  max-height: ${(props) => (props.isSquare ? "500px" : "100vh")};
  background-color: ${(props) => props.theme.merino};

  ${media.desktop`
    max-height: 100vh;
  `}

  img:not(.arrow) {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    max-height: ${(props) => (props.isSquare ? "500px" : "100vh")};
    height: ${(props) => (props.isSquare ? "500px" : "100%")};
    
  }
`;

const Title = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;  
  color: ${(props) => props.theme.white};  
  font: normal 44px "Saol Text Book";
  text-align: center;
  width: 100%;
  padding: 0 30px;
  line-height: 1em;

  ${media.tablet`
    font-size: 75px;
  `}

  ${media.desktop`
    font-size: 85px;
  `}
`

const Cta = styled.div`
  position: absolute;
  left: 50%;
  bottom: 150px;
  transform: translateX(-50%);
  z-index: 10;
  font: 20px/28px "Calluna Regular";
  font-style: italic;
  text-align: center;
  color: ${(props) => props.theme.white};

  div {
    margin: 0 0 10px 0;
    &:last-child {
      margin: 0;
    }
  }

  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }

  span {
    border-bottom: solid 1px ${(props) => props.theme.white};
  }
`

const Hero = ({
  heroImageDesktop,
  heroImageMobile,
  heroHeadline,
  onLearnMoreClick
}) => (
  <HeroWrapper>
    <Desktop>
      <ProgressiveImage src={heroImageDesktop}>
        {(src) => <img src={src} alt='' />}
      </ProgressiveImage>
    </Desktop>
    <Tablet>
      <ProgressiveImage src={heroImageMobile}>
        {(src) => <img src={src} alt='' />}
      </ProgressiveImage>
    </Tablet>
    <Title onClick={() => { onLearnMoreClick() }}>
      {heroHeadline.split('\n').map((line,i) => <span key={i}>{line}<br /></span>)}
    </Title>
    <Cta>
      <div>
        <a onClick={() => {onLearnMoreClick()}}>
          <span>Learn More About 1228 Madison Avenue</span> 
        </a>
      </div>
      <div>
        <Link to="/living-gallery">
          <span>Visit Kelly Behun’s Living Gallery Model Residence</span>
        </Link>
      </div>
    </Cta>
    <ArrowWrapper onClick={onLearnMoreClick} className="arrow-wrapper">

      <Arrow alt="Arrow Scroll Down" aria-hidden="true" className="arrow" src={arrowDown} />
    </ArrowWrapper>
  </HeroWrapper>
);

export const Splash = ({
  splashState,
  onLearnMoreClick,
  heroImage,
  heroImageMobile,
  heroHeadline
}) => {
  const sectionClassNames = ["quote-text-wrapper", splashState];
  
  return (
    <Container className={sectionClassNames.join(" ")}>
      <Hero
          onLearnMoreClick={onLearnMoreClick}
          heroImageMobile={heroImageMobile}
          heroImageDesktop={heroImage}
          heroHeadline={heroHeadline} />
    </Container>
  );
};
