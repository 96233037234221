import React from "react";
import styled from "styled-components";
import media from "../../../styles/media";
import logo from "../../../images/Logo.svg";
import { Link } from "gatsby";

export const Logo = styled.img`
  position: absolute;
  z-index: 1;
  width: 134px;
  top: 18px;
  cursor: pointer;

  left: 50%;
  transform: translate(-50%, 0);

  /* use white logo when the off color is white */
  ${({ theme }) =>
    theme.offColor === theme.white ? `filter: invert(100%);` : null}

  ${media.desktop`
    width: 164px;
 `};
`;

const noop = () => {};

const Img = ({ onClick = noop }) => (
  <Logo
    alt="1228 Madison Avenue Logo"
    src={logo}
    onClick={onClick}
    className="logo"
  />
);

export const MadisonLogo = ({ onLogoClick }) => {
  return onLogoClick ? (
    <Img onClick={onLogoClick} />
  ) : (
    <Link to="/">
      <Img />
    </Link>
  );
};
