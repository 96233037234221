import { css } from "styled-components";

// From https://www.styled-components.com/docs/advanced/#media-template

export const sizes = {
  desktopMax: 1600,
  desktopLarge: 1400,
  desktopMedium: 1200,
  desktop: 1024,
  tablet: 768,
  mobile: 512,
  mobileLarge: 425,
  mobileMedium: 375,
  mobileSmall: 320,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
