import React, { useRef } from "react";
import styled from "styled-components";
import media from "../../styles/media";
import Slider from "react-slick";
import blackArrow from "../../images/BlackArrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  SliderWrapper,
  Slide,
  DotsWrapper,
  HeroImage,
  ImageCaption,
  SliderWrapperCaption,
} from "./HeroCarousel";

const SlideCaption = styled(SliderWrapperCaption)`
  left: 2.5%;
  right: auto;
  top: 25px;

  ${media.desktop`
    top: 23px;
    right: 110px;
    left: auto;
    letter-spacing: 2px;
  `}
`;

const ArrowNext = styled.div`
  position: absolute;
  z-index: 10;
  top: 30.5%;
  left: 14px;
  cursor: pointer;

  ${media.mobileLarge`
    top: 25%;
  `}

  ${media.tablet`
    top: 15.5%;
    left: 20px;
  `}

  ${media.desktop`
    left: auto;
    right: 430px;
  `}
`;

export function InteriorHeroCarousel({
  slideOne,
  slideTwo,
  slideThree,
  bgColor,
}) {
  const slideRef = useRef();

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    draggable: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapper>{dots}</DotsWrapper>,
    customPaging: () => <button className='dot' />,
    nextArrow: <NextArrow />,
  };

  function NextArrow() {
    return (
      <ArrowNext
        onClick={() => {
          slideRef.current.slickNext();
        }}
      >
        <img src={blackArrow} />
      </ArrowNext>
    );
  }

  return (
    <SliderWrapper bgColor={bgColor}>
      <SlideCaption>
        Living &amp;&nbsp;Dining
        <br />
        Rooms
      </SlideCaption>
      <Slider {...settings} ref={slideRef}>
        <Slide>
          <HeroImage src={slideOne} />
          <ImageCaption>Grand House Great Room</ImageCaption>
        </Slide>
        <Slide>
          <HeroImage src={slideTwo} />
          <ImageCaption>Classic House Great Room</ImageCaption>
        </Slide>
        <Slide>
          <HeroImage src={slideThree} />
          <ImageCaption>Library House Great Room</ImageCaption>
        </Slide>
      </Slider>
    </SliderWrapper>
  );
}
