import React, { useState, useRef } from "react";
import styled from 'styled-components'
import media from '../../styles/media';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import mailchimp from '@mailchimp/mailchimp_transactional';

import {
  LivingGalleryRoomCarousel
} from '../../components/carousels/LivingGalleryRoomCarousel';

import ChevronDown from "../../images/chevron-down.svg";

/* Item */

const ItemContainer = styled.div`
  margin: 0 0 60px 0;
  text-align: left;
  flex: 1 0 100%;
  max-width: 400px;

  ${media.tablet`
    flex: 0 0 50%;
    text-align: center;
    max-width: none;
  `}  

  ${media.desktop`
    flex: 0 0 33.333%;    
  `}

  ${media.desktopLarge`
    flex: 0 0 25%;    
  `}
`;

const ItemInner = styled.div`
  padding: 0 30px;

  img {
    max-width: 100%;
  }  

  a {
    margin: 1em 0 0 0;
    display: inline-block;
    font-size: 13px;
    letter-spacing: .075em;
    padding-bottom: .05em;
    text-transform: uppercase;
    border-bottom: solid 1px ${(props) => props.theme.black};
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.black};
    &:hover {
      border-color: transparent;
    }
  }
`

const ItemHeader = styled.div`
  position: relative;
`

const ItemTitle = styled.div`
  font: 17px/20px "Calluna Regular";
  margin: 0 0 5px 0;
`
    
const ItemOrigin = styled.div`
  font: 17px/20px "Calluna Regular";
  font-style: italic;
`

const ItemImage = styled.div`
  margin: 20px 0;

  ${media.desktop`
    margin: 30px 0;
  `}  
`

const ItemDescription = styled.div`
  font: 14px/20px "Calluna Regular";
`

const FormEl = styled.form`
  margin: 30px auto 0 auto;

  ${media.tablet`
    max-width: 300px;
  `}
  

  &.is-loading {   
    cursor: wait;
    * {
      cursor: wait !important;
    }

    input {
      opacity: .5;
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  &.is-success fieldset {
    display: none;
  }
`

const FormGroup = styled.div`
  display: block;
  margin: 0 0 20px 0;

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px ${(props) => props.theme.black};
    border-radius: 0;
    text-align: left;
    padding: 0 0 10px 0;
    outline: none;
    -webkit-appearance: none;
    font: 14px/20px "Calluna Regular";
    &::placeholder {
      color: black;
    }

    ${media.tablet`
      text-align: center;
    `}
  }
`

const FormButton = styled.button`
  font: 16px "Calluna Regular";
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  padding: 10px 25px;
  border: none;
  cursor: pointer;
`

const FormNote = styled.div`
  margin: 20px 0 0 0;
  font-style: italic;
  font-size: 12px;
`

const SuccessMessage = styled.div`
  padding: 25px;
  border: solid 1px ${props => props.theme.black};
  margin: 30px auto 0 auto;
  max-width: 250px;
  font-style: italic;
  display: none;

  ${FormEl}.is-success & {
    display: block;
  }

  p {
    margin: 0;
  }
`
      
const Form = ({
  itemTitle,
  itemOrigin,
  onSuccess
}) => {
  const defaultData = {
    name: '',
    email: '',
    phone: '',
    notes: ''
  }
  
  const [data, setData] = useState(defaultData)  
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const mandrill = mailchimp(process.env.GATSBY_MANDRILL_API_KEY)

  let onChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }
  
  let onSubmit = e => {
    e.preventDefault()

    setLoading(true)

    mandrill.messages.sendTemplate({
      template_name: 'living-gallery-inquiry',
      template_content: [],
      message: {
        headers: {
          'Reply-To': data.email
        },
        to: [{
          email: 'gallery@1228madison.com',
          name: '1228 Madison'
        }],
        subject: 'Living Gallery Inquiry',
        global_merge_vars: [
          {
            name: 'item_title',
            content: itemTitle
          },
          {
            name: 'item_origin',
            content: itemOrigin
          },
          {
            name: 'name',
            content: data.name
          },
          {
            name: 'email',
            content: data.email
          },
          {
            name: 'phone',
            content: data.phone
          },
          {
            name: 'notes',
            content: data.notes
          }
        ]                
      }      
    }).then(response => {
      if ( response.response && response.response.status != 200 ) {
        alert('There was an error processing your request.')
        console.log('MC_ERROR:', response.response.data.message)
      } else {
        setData(defaultData)
        setLoading(false)
        setSuccess(true)
        onSuccess()
      }
    })
  }

  let className = ''
  if ( loading )
    className += ' is-loading'
  
  if ( success )
    className += ' is-success'
  
  return (
    <FormEl onSubmit={onSubmit} className={className}>
      <fieldset disabled={loading}>
        <FormGroup>
          <input
              type="text"
              name="name"
              value={data.name}
              placeholder="Name*"
              onChange={onChange} required />
        </FormGroup>
        <FormGroup>
          <input
              type="email"
              name="email"
              placeholder="Email*"
              value={data.email}
              onChange={onChange} required />
        </FormGroup>
        <FormGroup>
          <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={data.phone}
              onChange={onChange} />
        </FormGroup>
        <FormGroup>
          <input
              type="text"
              name="notes"
              placeholder="Notes"
              value={data.notes}
              onChange={onChange} />
        </FormGroup>
        
        <FormButton>Submit</FormButton>

        <FormNote>* Required Fields</FormNote>
      </fieldset>
      <SuccessMessage>
        <p>Thank you for your interest in this item. A member of our team will be in touch soon.</p>
      </SuccessMessage>
    </FormEl>
  )
}

const Item = ({
  title,
  origin,
  image,
  description,
  externalURL
}) => {
  const [formOpen, setFormOpen] = useState(false)

  const renderLink = () => {
    if ( externalURL ) {
      return <a href={externalURL} target="_blank">Learn More</a>
    } else {
      return formOpen ? null : <a onClick={() => setFormOpen(!formOpen)}>Inquire</a>
    }
  }

  const onSuccess = () => {
    setTimeout(() => {
      setFormOpen(false)
    }, 5000)
  }
  
  return (
    <ItemContainer>
      <ItemInner>
        <ItemHeader>
          <ItemTitle>{title}</ItemTitle>
          <ItemOrigin>{origin}</ItemOrigin>
        </ItemHeader>
        <ItemImage>
          <img src={image.file.url} />
        </ItemImage>
        <ItemDescription>{description.description}</ItemDescription>

        {renderLink()}                
        {formOpen ? <Form onSuccess={onSuccess} itemTitle={title} itemOrigin={origin} /> : null}
      </ItemInner>
    </ItemContainer>
  )
}

/* Room Content */

const RoomContentContainer = styled.div`
  text-align: center;
`

const IntroContainer = styled.div`
  text-align: left;
  background: ${(props) => props.theme.white};
  padding: 30px 30px;
  ${media.tablet`
    text-align: center;
    padding: 75px 30px;
  `}

  ${media.desktop`
    padding: 120px 30px;
  `}
`;

const Intro = styled.div`
  margin: 0 auto;
  max-width: 800px;
`;

const IntroTitle = styled.h2`
  font: normal 36px "Saol Text Book";
  margin: 0;

  ${media.tablet`
    font: normal 44px "Saol Text Book";
  `}
`;

const IntroDescription = styled.p`
  font: 13px/23px "Calluna Light";
  margin-top: 14px;  

  ${media.tablet`
    font: 20px/30px "Calluna Regular";
  `}  
`;

const ItemsContainer = styled.div`   
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 0;
  transition: margin-bottom 500ms ease;

  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 85%;
    height: 1px;
    background-color: black;      
    transform: translateX(-50%);
    transition: opacity 500ms ease;
  }

  .is-open & {
    margin-bottom: 75px;
  }

  ${media.tablet`
    padding: 75px 30px;
  `}  

  ${media.desktop`
    padding: 120px 50px;
  `}  

  ${media.desktopLarge`
    padding: 120px 120px;
  `}  
`;

const RoomContent = ({ title, longTitle, description, images=[], items=[] }) => {
  return (
    <RoomContentContainer>
      <LivingGalleryRoomCarousel images={images} />      
      <IntroContainer>
        <Intro>
          <IntroTitle>{longTitle}</IntroTitle>
          <IntroDescription>{description}</IntroDescription>
        </Intro>
      </IntroContainer>
      <ItemsContainer>
        {items.map((item,i) => <Item {...item} key={i}></Item>)}
      </ItemsContainer>
    </RoomContentContainer>
  )  
}

/* Room */

const RoomContainer = styled.div`
  position: relative;
  margin: 20px 0;
  text-align: center;
  transition: margin-top 500ms ease, padding-top 500ms ease;
  
  &:first-child {
    margin-top: 0 !important;

    &:before {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    width: 85%;
    height: 1px;
    background-color: black;      
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 500ms ease;
  }

  &.is-open {
    margin-top: 50px;
    padding-top: 50px;     
    
    ${media.tablet`
      padding-top: 75px;
    `}
  
    &:before {
      opacity: 1;
    }
  }
`

const RoomTitle = styled.div`
  position: relative;
  text-align: center;

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    opacity: 0;
    margin: 10px auto 0 auto;
    transition: opacity 400ms ease;
    z-index: 10;

    ${RoomContainer}.is-open & {
      opacity: 1;
    }
  }
`

const RoomTitleA = styled.a`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  letter-spacing: .05em;
  padding: 0 0 .05em 0;
  border-bottom: solid 1px ${(props) => props.theme.black};

  &:hover {
    border-color: transparent;
  }
`

const Room = ({
  title,
  longTitle,
  carousel = [],
  items = [],
  description,  
  isOpen = false,
  onTitleClick
}) => {  
  if ( description )
    description = description.description

  const ref = useRef()

  if ( ref.current && isOpen ) {
    setTimeout(() => {
      window.scrollTo({
        top: ref.current.getBoundingClientRect().y + window.pageYOffset - 100,
        left: 0,
        behavior: 'smooth'
      })
    }, 500)
  }
    
  return (
    <RoomContainer className={isOpen ? 'is-open' : null} ref={ref}>
      <RoomTitle>
        <RoomTitleA onClick={onTitleClick}>
          {title}
        </RoomTitleA>
        <img src={ChevronDown} />
      </RoomTitle>

      <SlideDown>
        {isOpen ? <RoomContent
            title={title}
            longTitle={longTitle}
            description={description}
            images={carousel || undefined}
            items={items || undefined} /> : null}
      </SlideDown>
    </RoomContainer>
  )
}

export default Room
