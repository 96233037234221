import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import {
  CenterTextBox,
  CenterTitle,
  CenterParagraph,
  CenterTitleMobile,
  CenterParagraphMobile,
} from "./InteriorCenterText";

const Desktop = styled.div`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;

  ${media.desktop`
    display: none;
  `}
`;

const CenterTextWrapper = styled.section`
  display: flex;
  background-color: ${(props) => props.theme.merino};
  color: ${(props) => props.theme.oxBlood};
`;

const AmenitiesTextBox = styled(CenterTextBox)`
  ${media.tablet`
    padding-bottom: 146px;
  `}
`;

const ParagraphMobile = styled(CenterParagraphMobile)`
  margin-top: 22px;
  margin-bottom: 8px;
  padding-bottom: 42px;
`;

export const AmenitiesCenterText = ({ title, mobileTitle, description }) => {
  return (
    <>
      <Desktop>
        <CenterTextWrapper>
          <AmenitiesTextBox>
            <CenterTitle>{title}</CenterTitle>
            <CenterParagraph>{description}</CenterParagraph>
          </AmenitiesTextBox>
        </CenterTextWrapper>
      </Desktop>
      <Tablet>
        <CenterTextWrapper>
          <CenterTitleMobile>
            <span dangerouslySetInnerHTML={{ __html: mobileTitle }} />
            <ParagraphMobile>{description}</ParagraphMobile>
          </CenterTitleMobile>
        </CenterTextWrapper>
      </Tablet>
    </>
  );
};
