import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import { SectionWrapper } from "../../common/Section";
import { PageTitleCaption } from "../../common/Header/PageTitle";
import { Logo } from "../Header/MadisonLogo";
import logoImg from "../../../images/LightMadison.svg";
import media from "../../../styles/media";
import whiteLine from "../../../images/WhiteLine.png";
import whitex from "../../../images/whitex.svg";

export const LegalWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.oxBlood};
  color: ${(props) => props.theme.white};
  justify-content: center;
  align-items: center;
`;

export const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.desktop`
    margin-top: 7%;
    flex-direction: row;
    padding-bottom: 0;
  `}
`;

export const LegalBox = styled.div`
  display: flex;
  box-sizing: border-box;

  ${media.desktop`
    width: 50%;
    height: 510px;
    border-right: 1px solid white;
  `}
`;

export const TextBox = styled(LegalBox)`
  border-right: none;
  overflow-y: scroll;
  height: 250px;
  margin-top: 20px;

  ${media.tablet`
    border-right: none;
    overflow-y: scroll;
    height: 510px;
    margin-top: auto;
  `}

  ${media.desktopMax`
    overflow: hidden;
    height: none;
  `}
`;

export const TextContainer = styled.div`
  position: relative;
  font: 12px/20px "Calluna Regular";
  padding: 0 32px 0 32px;

  p {
    margin-top: 5px;

    ${media.desktop`
      margin-top: auto;
    `}
  }

  ${media.desktop`
    top: 18%;
    width: 60%;
    left: 19%;
    padding: 0;
  `}

  ${media.desktopMax`
    @media (min-height: 900px) {
      left: 26%;
      top: 18%;
    }
  `}

  a {
    color: white;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  left: 9.5%;
  top: 8px;

  ${media.tablet`
    top: 17.1%;
    left: 30px;
  `}

  ${media.desktop`
    left: 23%;
  `}
`;

export const Title = styled.h1`
  font: normal 60px/55px "Saol Text Book";
  letter-spacing: 2.8px;
  border-bottom: 1px solid white;
  padding-bottom: 30px;
  margin-bottom: 0;

  ${media.desktop`
    font-size: 98px;
    border-bottom: none;
    padding-bottom: 0;
  `}

  ${media.desktopMax`
    font-size: 133px;
  `}
`;

const pageCaption = "Legal and Privacy";

export const LogoSmall = styled(Logo)`
  ${media.desktop`
    top: 40px;
    width: 168px;
  `}
`;

const PageTitleLegal = styled(PageTitleCaption)`
  color: white;
  &:before,
  :after {
    background-image: url(${whiteLine});
  }

  ${media.desktop`
    left: 102px;
  `}
`;

export const Close = styled.button`
  position: absolute;
  z-index: 2000;
  top: 47px;
  right: 23px;
  transform: translate(-50%, 0);
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  ${media.desktop`
    left: auto;
    transform: none;
    top: 69px;
    right: 100px;
  `}
`;

export const CloseImage = styled.img``;

export const LegalModal = ({ closeModal, legalText }) => (
  <LegalWrapper>
    <PageTitleLegal>{pageCaption}</PageTitleLegal>
    {closeModal && (
       <Close onClick={closeModal}>
         <CloseImage src={whitex} alt='Close' />
       </Close>
     )}
    <Link to='/'>
      <LogoSmall alt='1228 Madison' src={logoImg} />
    </Link>
    <LegalContainer>
      <LegalBox>
        <TitleContainer>
          <Title>Legal</Title>
        </TitleContainer>
      </LegalBox>
      <TextBox>
        <TextContainer dangerouslySetInnerHTML={{ __html: legalText }} />
      </TextBox>
    </LegalContainer>
  </LegalWrapper>
);
