import React from "react";
import styled from "styled-components";
import { Layout } from "../styles/Layout";
import { Link } from "gatsby";
import theme from "../styles/theme";
import media from "../styles/media";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/common/SEO";

import { createGlobalStyle } from "styled-components";

const BodyColor = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.offWhite};
  }
`;

const Plans = styled.table`
  background-color: ${({ theme }) => theme.offWhite};
  border-collapse: collapse;
  width: 85%;
  margin: 66px 7.5% 103px 7.5%;

  td {
    padding: 0;
    font: normal 15px "Calluna light";
  }

  ${media.desktop`
    width: 81%;
    margin: 85px 9.5% 103px 9.5%;

    th {
      height: 85px;
      padding: 0;
    }

    td {
      height: 94px;
      padding: 0;
    }
  `}
`;

const TH = styled.th`
  display: none;
  ${media.desktop`
    display: table-cell;
    color: ${({ theme }) => theme.oxBlood};
    font: normal 19px "Calluna Regular";
    text-transform: uppercase;
  `}
`;

const TD = styled.td`
  display: block;
  width: 100%;
  text-align: center;
  font: normal 19px "Calluna light";
  letter-spacing: 0.5px;

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.oxBlood};
    font-size: 25px;
    letter-spacing: 1px;
    padding-top: 23px;
  }

  &:nth-child(2) {
    margin-top: 18px;
  }

  &:nth-child(4),
  &:nth-child(6) {
    margin-top: 16px;
  }

  &:last-child {
    margin-top: 13px;
    padding-bottom: 25px;
  }

  a {
    text-transform: uppercase;
    font: normal 12px "Calluna Regular";
    letter-spacing: 0.8px;
    color: ${({ theme }) => theme.oxBlood};
  }

  ${media.desktop`
    display: table-cell;
    width: auto;
    border-top: 1px solid ${({ theme }) => theme.oxBlood};
    font: normal 19px "Calluna light";
    text-align: center;

    &:first-child {
      width: 11%;
      padding-top: 0;
      text-align: left;
      font-size: 19px;
    }

    :nth-last-child(2) {
      width: 15%;
    }

    &:last-child {
      width: 12%;
      text-transform: none;
      padding: 0;
    }

    a {
      color: black;
      text-decoration: none;
      text-transform: none;
    }

    span {
      display: none;
    }
  `}
`;

const Floor = ({
  name,
  baths,
  bedrooms,
  interiorSquareFeet,
  exteriorSquareFeet,
  priceAmount,
  floorPlanPdf,
  inContract,
}) => {
  return (
    <tr>
      <TD>{name}</TD>
      <TD>
        <span>Bedrooms:&nbsp;</span>
        {bedrooms}
      </TD>
      <TD>
        <span>Baths:&nbsp;</span>
        {baths}
      </TD>
      <TD>
        <span>INTERIOR. SQ. FT:&nbsp;</span>
        {interiorSquareFeet && interiorSquareFeet}
      </TD>

      <TD>
        <span>EXTERIOR. SQ. FT:&nbsp;</span>
        {exteriorSquareFeet && exteriorSquareFeet}
      </TD>

      <TD>
        <span>Price:&nbsp;</span>
        {priceAmount}
      </TD>
      <TD>
        {!inContract ? (
          <a href={floorPlanPdf.file.url} target='floorplan'>
            View<span>&nbsp;Floor Plan</span>
          </a>
        ) : (
          <a>In Contract</a>
        )}
      </TD>
    </tr>
  );
};

const FloorPlans = () => {
  const data = useStaticQuery(FloorPlanQuery);
  const floorPlanContent = data.allContentfulFloorPlan.edges[0].node;
  const title = floorPlanContent.title;
  const description = floorPlanContent.description;
  const floors = floorPlanContent.floors || [];

  return (
    <Layout
      menucolor={theme.offWhite}
      bgColor={theme.merino}
      pageTitle='Availability'
    >
      <SEO title={title} description={description} path='/availability' />
      <BodyColor />

      <Plans>
        <thead>
          <tr>
            <TH>Floor</TH>
            <TH>Bedrooms</TH>
            <TH>Baths</TH>
            <TH>Interior. Sq. Ft.</TH>
            <TH>Exterior. Sq. Ft.</TH>
            <TH>Price</TH>
            <TH>Floor Plan</TH>
          </tr>
        </thead>
        <tbody>
          {floors.map((floor, i) => (
            <Floor {...floor} key={`row${i}`} />
          ))}
        </tbody>
      </Plans>
    </Layout>
  );
};

export default FloorPlans;

export const FloorPlanQuery = graphql`
  query GetFloorPlan {
    allContentfulFloorPlan {
      edges {
        node {
          title
          description
          floors {
            name
            baths
            bedrooms
            interiorSquareFeet
            exteriorSquareFeet
            priceAmount
            floorPlanPdf {
              file {
                url
              }
            }
            inContract
          }
        }
      }
    }
  }
`;
