import React from "react";
import styled from "styled-components";
import { ArchitectMobileCarousel } from "../carousels/ArchitectMobileCarousel";
import { GutterAddresses } from "../common/Section";
import { Desktop, Tablet } from "../common/media";
import {
  ImageWrapper,
  ImageCaption,
  HeroAssetDesktopImage,
} from "../../components/SplitModule/ImageSplit";
import media from "../../styles/media";
import buildingFacade from "../../images/BuildingFacadeFull.jpg";

const ArchitectWrapper = styled(ImageWrapper)``;

const ImageTextContainer = styled.figure`
  position: relative;
  margin: 0;

  img {
    max-width: 518px;
    width: 100%;
  }
`;

const ArchitectCaption = styled(ImageCaption)`
  position: absolute;
  width: 200px;
  bottom: 42px;
  right: 27%;
  transform: translateX(100px);
  text-align: center;
`;

export const ArchitectImageSplit = ({ light }) => (
  <ArchitectWrapper light={light}>
    <Desktop>
      <ImageTextContainer>
        <img alt="Process Sketch by Robert A.M. Stern Architects" src={buildingFacade} />
        <ArchitectCaption>
          Process Sketch by <br /> Robert A.M. Stern Architects
        </ArchitectCaption>
      </ImageTextContainer>
    </Desktop>
    <Tablet>
      <ArchitectMobileCarousel />
    </Tablet>
    <GutterAddresses />
  </ArchitectWrapper>
);
