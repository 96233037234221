import React from "react";
import styled from "styled-components";
import { GutterAddresses, SectionWrapper } from "../common/Section";
import media from "../../styles/media";
import {
  ImageWrapper,
  HeroWrapper,
  HeroAssetDesktopImage,
  ImageCaption,
} from "./ImageSplit";

const ImageHeroWrapper = styled(ImageWrapper)`
  padding: 50px 0;
  background-color: ${(props) => props.theme.white};
`;

const IndexImageCaption = styled(ImageCaption)`
  left: 16%;
  top: 82.8%;
`;

const IndexImageRightCaption = styled(ImageCaption)`
  top: 70%;
  right: 32%;
  transform: translateX(100px);
`;

const MobileCaption = styled(ImageCaption)`
  top: 44%;
  right: 8px;
  margin-top: 1px;
  white-space: pre;
`;

const HeroAssetTabletImage = styled.img`
  max-height: 100vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const TabletWrapper = styled(SectionWrapper)`
  padding: 50px 0;
  background-color: ${(props) => props.theme.white};
`;

const ImageCaptionContainer = styled.div`
  position: relative;
`;

const Desktop = styled.div`
  display: none;
  ${media.desktop`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;
  ${media.desktop`
    display: none;
  `}
`;

export const IndexImageSplit = ({
  light,
  desktopImage,
  mobileImage,
  imageCaption,
}) => (
  <>
    <Desktop>
      <ImageHeroWrapper>
        <HeroWrapper>
          <ImageCaptionContainer>
            <HeroAssetDesktopImage src={desktopImage} />
            {imageCaption && (
              <IndexImageCaption
                dangerouslySetInnerHTML={{ __html: imageCaption }}
              />
            )}
            <IndexImageRightCaption>
              Serpentine Façade Detail
            </IndexImageRightCaption>
          </ImageCaptionContainer>
        </HeroWrapper>
        <GutterAddresses />
      </ImageHeroWrapper>
    </Desktop>
    <Tablet>
      <TabletWrapper>
        <HeroAssetTabletImage src={mobileImage} />
        {imageCaption && (
          <MobileCaption>Serpentine Façade Detail</MobileCaption>
        )}
      </TabletWrapper>
    </Tablet>
  </>
);
