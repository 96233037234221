import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Link } from "gatsby";
import { slide as Menu } from "react-burger-menu";

const Hr = styled.hr`
  width: 1px;
  border-style: solid;
  border-color: white;
  border-width: 7vh 0 0 0;

  /* Removes hightlight on tab highlight */
  outline: none;
`;

const Title = styled.div`
  padding-top: 7.5vh;
  margin-bottom: 3.5vh;

  div {
    border-bottom: 1px solid white;
    width: 12vh;
    margin: auto;
  }

  span {
    transform: translateX(8px);
    display: inline-block;
    font-family: "Saol Text Book";
    font-size: 2.85vh;
    letter-spacing: 1vh;
    padding-bottom: 5px;
    transform: translateX(0.4vh);
  }

  /* Removes hightlight on tab highlight */
  outline: none;
`;

const Item = styled(Link)`
  display: block;
  margin: 3.3vh 13% 3.45vh 13%;

  color: ${(props) => props.theme.white};
  font: 2.55vh "Calluna Light";
  letter-spacing: 0.1vh;
  text-decoration: none;

  /*
  Apply border same background color so when white
  focus border appears, the menu doesn't jump around
  */
  border-bottom: 1px solid ${(props) => props.theme.oxBlood};

  &.small {
    font-size: 1.8vh;
    margin-top: -2.4vh;
  }

  &:focus,
  &:hover {
    outline: none;
    border-bottom: 1px solid ${(props) => props.theme.white};
  }
`;

const BurgerMenu = styled.div`
  .bm-menu {
    overflow: hidden;
  }

  .bm-burger-button {
    position: absolute;
    top: 34px;
    right: 35px;
    height: 19px;
    width: 30px;
    outline: none;
  }

  .bm-burger-bars {
    background-color: ${(props) => props.theme.offColor};
    height: 1px !important;
    width: 25px !important;
  }

  .bm-cross-button {
    height: 30px;
    width: 15px;
    outline: none;

    button {
      top: 4px !important;
      right: 3px !important;
      left: auto !important;
    }
  }

  .bm-cross {
    top: 4px;
    right: 3px;
    height: 22px !important;
    width: 1px !important;
    background: ${(props) => props.theme.white};
    outline: none;
  }

  .bm-menu {
    width: 100%;
    height: 100vh !important;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.oxBlood};
    text-align: center;
  }

  .bm-overlay {
    height: 100vh !important;
  }
`;

export const MadisonMenu = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [width, setWidth] = useState("100%");

  /**
   * This is to fix a problem with the prerendered version of the site
   * having the menu on the left instead of the right
   */
  useEffect(() => {
    setWidth(isDesktop ? "400px" : "100%");
  }, [width, isDesktop]);

  return (
    <BurgerMenu>
      <Menu right disableAutoFocus width={width} bodyClassName="no-scroll">
        <Title>
          <div>
            <span>MENU</span>
          </div>
        </Title>

        <Hr />

        <Item to="/" className="menu-item">
          Architecture
        </Item>
        
        <Item to="/architect/" className="small">
          Architect
        </Item>

        <Item to="/interiors">Interiors</Item>
        <Item to="/interiordesigner/" className="small">
          Interior&nbsp;Designer
        </Item>

        <Item to="/living-gallery/">
          Kelly Behun’s Living Gallery
        </Item>

        <Item to="/amenities">Amenities</Item>
        <Item to="/films">Films</Item>
        <Item to="/availability">Availability</Item>
        <Item to="/contact">Contact</Item>
        <Item to="/team">Team</Item>
        <Item to="/press" style={{ marginBottom: "0" }}>
          Press
        </Item>

        <Hr
          style={{ marginBottom: "7.5vh", marginTop: "3.5vh" }}
          className="bot"
        />
      </Menu>
    </BurgerMenu>
  );
};
