import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "./theme";
import GlobalFonts from "./fonts";
import { Footer } from "../components/common/Footer";
import { Header } from "../components/common/Header/Header";

const GlobalStyle = createGlobalStyle`
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .no-scroll {
    overflow-y: hidden !important;
  }

  * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Layout = ({
  children,
  menucolor,
  bgColor,
  onLogoClick,
  pageTitle,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Header
        menucolor={menucolor}
        onLogoClick={onLogoClick}
        pageTitle={pageTitle}
      />
      <GlobalStyle />
      <GlobalFonts />
      <main>{children}</main>
      <Footer bgColor={bgColor} />
    </ThemeProvider>
  );
};
