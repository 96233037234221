import React, { useState } from "react";
import { Layout } from "../styles/Layout";
import Form from "../components/Form/Form";
import { FormSuccess } from "../components/Form/FormSuccess";
import theme from "../styles/theme";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/common/SEO";

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const data = useStaticQuery(ContactQuery);
  const contactContent = data.allContentfulInquire.edges[0].node;
  const title = contactContent.title;
  const descripton = contactContent.descripton;

  const displaySuccess = () => {
    setFormSubmitted(true);
  };

  return (
    <Layout bgColor={theme.merino} pageTitle='Contact'>
      <SEO title={title} description={descripton} path='/contact' />
      {formSubmitted ? (
        <FormSuccess handleClose={() => setFormSubmitted(false)} />
      ) : (
        <Form displaySuccess={displaySuccess} />
      )}
    </Layout>
  );
};

export default Contact;

export const ContactQuery = graphql`
  query GetContact {
    allContentfulInquire {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;
