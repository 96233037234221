import React from "react";
import styled from "styled-components";
import media from "../../styles/media";

const Desktop = styled.div`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;

  ${media.desktop`
    display: none;
  `}
`;

export const CenterTextWrapper = styled.section`
  display: flex;
  background-color: ${(props) => props.theme.merino};
  color: ${(props) => props.theme.oxBlood};
`;

export const CenterTextBox = styled.div`
  text-align: center;

  ${media.tablet`
    width: 700px;
    margin: 0 auto;
    padding: 123px 0 145px 0;
  `};

  ${media.desktop`
    width: 850px;
  `};
`;

export const CenterTitle = styled.h1`
  font: normal 44px "Saol Text Book";
  margin-bottom: 0;
`;

export const CenterTitleMobile = styled(CenterTitle)`
  font-size: 35px;
  line-height: 42px;
  margin-left: 30px;
`;

export const CenterParagraph = styled.p`
  font: 20px/30px "Calluna Regular";
  margin-top: 14px;
`;

export const CenterParagraphMobile = styled.p`
  font: 13px/23px "Calluna Light";
  padding: 0 30px 38px 0;
  margin-top: 20px;
`;

export const LifestyleGalleryCenterText = ({
  desktopTitle,
  mobileTitle,
  description,
}) => {
  return (
    <>
      <Desktop>
        <CenterTextWrapper>
          <CenterTextBox>
            <CenterTitle>{desktopTitle}</CenterTitle>
            <CenterParagraph>{description}</CenterParagraph>
          </CenterTextBox>
        </CenterTextWrapper>
      </Desktop>
      <Tablet>
        <CenterTextWrapper>
          <CenterTitleMobile>
            <span dangerouslySetInnerHTML={{ __html: mobileTitle }} />

            <CenterParagraphMobile>{description}</CenterParagraphMobile>
          </CenterTitleMobile>
        </CenterTextWrapper>
      </Tablet>
    </>
  );
};
