import React from "react";
import styled from "styled-components";
import media from "../styles/media";
import { Layout } from "../styles/Layout";
import { PressArticles } from "../components/Press/PressArticles";
import { useStaticQuery, graphql } from "gatsby";
import theme from "../styles/theme";
import SEO from "../components/common/SEO";

const PressTitle = styled.h1`
  position: absolute;
  z-index: 1;
  font: normal 20px/30px "Saol Text Book";
  top: 130px;
  color: ${(props) => props.theme.white};
  left: 50%;
  transform: translate(-50%, 0);
  text-transform: uppercase;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  ${media.tablet`
    top: 100px;
  `}

  ${media.desktop`
    font-size: 50px;
    line-height: 63px;

    @media (min-height: 700px) {
      top: 120px;
    }

    @media (min-height: 1000px) {
      top: 150px;
    }
  `}
`;

const PressPage = () => {
  const data = useStaticQuery(PressQuery);
  const pressContent = data.allContentfulPressPage.edges[0].node;
  const title = pressContent.title;
  const description = pressContent.description;

  return (
    <Layout bgColor={theme.merino} menucolor={theme.oxBlood} pageTitle="Press">
      <SEO title={title} description={description} path="/press" />
      <PressTitle>press</PressTitle>
      <PressArticles />
    </Layout>
  );
};
export default PressPage;

const PressQuery = graphql`
  query GetPressQuery {
    allContentfulPressPage {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;
