import React from "react";
import media from "../styles/media";
import styled from "styled-components";
import { SectionWrapper, GutterAddresses } from "../components/common/Section";

const QuoteWrapper = styled(SectionWrapper)`
  z-index: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.oxBlood};
  color: ${(props) => props.theme.offWhite};
`;

const QuoteText = styled.h1`
  font-family: "Saol Display Medium";
  font-size: 37px;
  margin: 0;
  width: 100%;
  padding: 0px 0 35px 0;
  font-weight: normal;
  line-height: 43px;

  ${media.tablet`
    font-size: 66px;
    line-height: 80px;
  `}

  ${media.desktopLarge`
    @media (min-height: 700px) {
     padding: 80px 0 65px 0;
     font-size: 90px;
     line-height: 110px;
    }
  `}

  .quote-start {
    font-size: 105px;
    line-height: 0;
    transform: translate(-4px, 0);

    ${media.tablet`
      font-size: 130px;
      transform: translate(4px, 7px);
    `}

    ${media.desktopLarge`
      @media (min-height: 700px) {
       font-size: 180px;
       transform: translate(0, 10px);
      }
    `}
  }

  .quote-end {
    font-size: 105px;
    line-height: 0;
    transform: translate(0, 61px);

    ${media.tablet`
      font-size: 130px;
      transform: translate(4px, 80px);
    `}

    ${media.desktopLarge`
      @media (min-height: 700px) {
       font-size: 180px;
       transform: translate(0, 107px);
      }
    `}
  }
`;

const ArchitectWrapper = styled.div`
  position: absolute;
  font-family: "Calluna light";
  margin-top: 20px;

  @media (min-height: 500px) {
    bottom: 145px;
  }

  ${media.tablet`
    bottom: 50px;

    @media(min-height: 700px){
      bottom: 70px;
    }
    @media(min-height: 800px){
      bottom: 170px;
    }
  `}

  ${media.desktopLarge`
    @media (min-height: 800px) {
      bottom: 130px;
    }
  `}
`;

const ArchitectName = styled.p`
  font-size: 15px;
  margin-bottom: 5px;

  ${media.desktopLarge`
    @media (min-height: 900px) {
     font-size: 24px;
     margin-bottom: 10px;
    }
  `}
`;

const ArchitectFirm = styled.p`
  font-size: 13px;
  margin-top: 0;

  ${media.desktopLarge`
    @media (min-height: 900px) {
     font-size: 16px;
     letter-spacing: 0.35px;
    }
  `}
`;

export const Quote = () => (
  <QuoteWrapper>
    <QuoteText>
      <div className='quote-start'>“</div>
      The only real luxury <br /> left is individuality.
      <div className='quote-end'>”</div>
    </QuoteText>
    <ArchitectWrapper>
      <ArchitectName>Paul&nbsp;Whalen</ArchitectName>
      <ArchitectFirm>Robert A.M. Stern Architects</ArchitectFirm>
    </ArchitectWrapper>
    <GutterAddresses />
  </QuoteWrapper>
);
