import styled from "styled-components";
import { SectionWrapper } from "../common/Section";

export const ImageWrapper = styled(SectionWrapper)`
  background-color: ${props =>
    props.light ? props.theme.offWhite : props.theme.merino};
  padding: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const HeroWrapper = styled.section`
  position: relative;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
`;

export const HeroAssetDesktopImage = styled.img`
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const TabletImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 34px);
  height: calc(100% - 32px);
  object-fit: cover;
`;

export const ImageCaption = styled.figcaption`
  position: absolute;
  font-family: "Calluna Italic";
  font-size: 12px;
`;
