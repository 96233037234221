import React from "react";
import styled from "styled-components";
import media from "../../styles/media";

export const TeamContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1920px;
  top: 1%;
  z-index: 1;

  ${media.desktop`
    flex-direction: row;
    top: -7%;
  `}
`;

export const TeamBox = styled.div`
  display: flex;
  box-sizing: border-box;

  ${media.desktop`
    width: 50%;
    height: 510px;
    border-right: 1px solid black;
  `}
`;

export const TitleContainer = styled.div`
  position: relative;
  left: 32px;
  text-align: left;
  cursor: pointer;
  top: 17.1%;
  border-bottom: 1px solid black;
  padding-bottom: 25px;

  ${media.desktop`
    text-align: center;
    top: 17.1%;
    left: 27%;
    border-bottom: none;
  `}

  ${media.desktopLarge`
    top: auto;
    left: 23%;
  `}

  ${media.desktopMax`
    @media (min-height: 1000px) {
      top: -6%;
      left: 24.5%;
    }
  `}

  .active {
    text-align: left;
    border-bottom: 1px solid black;
    width: fit-content;
    margin: 0;
    margin-bottom: 10px;

    ${media.desktop`
      text-align: center;
      margin: 0 auto;
      margin-bottom: 25px;
    `}

    ${media.desktopLarge`
      margin-bottom: 46px;
    `}
  }
`;

export const Title = styled.h1`
  font: normal 21px/25px "Saol Text Book";
  letter-spacing: 1px;
  margin: 0 auto;
  padding-bottom: 1px;
  margin-bottom: 10px;
  white-space: pre;

  ${media.desktop`
    font-size: 33px;
    line-height: 30px;
    letter-spacing: 1.4px;
    margin-bottom: 25px;
    padding-bottom: 5px;
  `}

  ${media.desktopLarge`
    font-size: 57px;
    line-height: 52px;
    margin-bottom: 46px;
    letter-spacing: 2px;
  `}
`;

const TextContainer = styled.div`
  position: relative;
  padding: 0 28px;
  top: -4%;

  p {
    font: 13px/23px "Calluna Regular";
    margin-top: 5px;

    ${media.desktop`
      margin-top: auto;
      font-size: 15px;
      line-height: 25px;
    `}
  }

  ${media.desktop`
    top: 1.5%;
    width: 60%;
    left: 18.5%;
    padding: 0;
  `}

  ${media.desktopMax`
    @media (min-height: 1000px) {
      left: 22.5%;
    }
  `}
`;

export const TextBox = styled(TeamBox)`
  border-right: none;
  overflow-y: scroll;
  height: 369px;
  margin-top: 20px;

  ${media.desktop`
    border-right: none;
    margin-top: none;
  `}
`;

const TextTitle = styled.h3`
  font: normal 20px/22px "Calluna Regular";
  margin-bottom: 10px;
  letter-spacing: 1.8px;

  ${media.desktop`
    font-size: 29px;
    line-height: 27px;
  `}

  ${media.desktopLarge`
    font-size: 43px;
    line-height: 39px;
  `}
`;

const TextSubTitle = styled.h6`
  font: normal 13px/16px "Calluna Semibold";
  margin: 22px 0 20px 0;
  text-transform: uppercase;
  letter-spacing: 1.4px;

  ${media.desktop`
    margin: 22px 0 28px 0;
  `}

  ${media.desktopLarge`
    font-size: 16px;
    line-height: 19px;
  `}

  ${media.desktopMax`
    @media (min-height: 1000px) {
      margin: 25px 0 40px 0;
    }
  `}
`;

export const teamInfo = {
  "Architecture": (
    <TextContainer>
      <TextTitle>
        Robert A.M. Stern
        <br />
        Architects
      </TextTitle>
      <TextSubTitle>architecture</TextSubTitle>
      <p>
        Robert A.M. Stern Architects, LLP, is a 265-person firm of architects,
        interior designers, and supporting staff. Over its fifty-year history,
        the firm has established an international reputation as a leading design
        firm with wide experience in residential, commercial, and institutional
        work. As the firm’s practice has diversified, its geographical scope has
        widened to include projects in Europe, Asia, South America, and
        throughout the United States. The firm maintains an attention to detail
        and commitment to design quality which has earned international
        recognition, numerous awards and citations for design excellence,
        including National Honor Awards of the American Institute of Architects,
        and a lengthening list of repeat clients.
      </p>
    </TextContainer>
  ),
  "Interiors": (
    <TextContainer>
      <TextTitle>Kelly&nbsp;Behun&nbsp;Studio</TextTitle>
      <TextSubTitle>interior design</TextSubTitle>
      <p>
        Kelly Behun Studio is an interior design firm based in New York City
        that specializes in the design of interiors, furniture and decorative
        accessories. Founded by Kelly Behun, the Studio provides a uniquely
        hands-on approach producing customized environments perfectly tailored
        to each client’s needs. Clients are assured a highly personal experience
        with an emphasis on collaboration and individualized service. Considered
        an interior designer with the eye of a gallerist, Kelly’s projects
        utilize everything from bespoke furnishings and unique designer pieces
        to offbeat accents and inexpensive vintage finds. The aesthetic relies
        more on visual and emotional appeal than a particular style or
        provenance. Kelly has deep connections to the art world and is always
        seeking new and innovative ways for clients to integrate art into their
        homes. Her portfolio includes projects for high profile individuals in
        the worlds of technology, entertainment, business and politics.
      </p>
    </TextContainer>
  ),
  "Development": (
    <TextContainer>
      <TextTitle>CBSK Ironstate</TextTitle>
      <TextSubTitle>development</TextSubTitle>
      <p>
        For the developers behind 1228 Madison —a long-term partnership of CB
        Developers, SK Development, and Ironstate Development, operating under
        the collective name CBSK Ironstate—roundtable decision making enables a
        bold design vision.
      </p>
      <p>
        "It's not design by committee," explains Scott Shnay, a third-generation
        developer and partner with his father, Abe, in SK Development. "The
        committee selects the architect, then we trust the architect to design,
        the same way we trust the contractor to build, and the marketing team to
        sell in their respective roles." The result is a portfolio of 15
        development projects in New York, Brooklyn, Queens, and the Hudson
        Valley.
      </p>
      <p>
        The other partners of CBSK Ironstate are Charles Blaichman and brothers
        Michael and David Barry of Ironstate. The partnership's strength
        consists in being a like-minded group of family companies that share
        multi-generational experience and a long-term vision.
      </p>
      <p>
        As hands-on developers who enjoy all phases of the development process,
        CBSK Ironstate plans and programs projects to be logical, efficient, and
        attractive to a large buyer pool. The partners agree, a collaborative
        team process is integral in achieving quality results: they take as much
        pride in the execution of their developments as in the forward-looking
        design outcomes.
      </p>
    </TextContainer>
  ),
  "Sales & Marketing": (
    <TextContainer>
      <TextTitle>
        Compass Development <br /> Marketing Group
      </TextTitle>
      <TextSubTitle>Exclusive Sales + Marketing</TextSubTitle>
      <p>
        Compass Development Marketing Group collaborates with the world’s most forward-thinking developers and innovative architects through the lifespan of a project, from a building’s inception to its sellout. Leveraging tenured expertise and the industry’s most powerful technological resources, the team offers a comprehensive range of developer services, such as research and analysis, planning and design, marketing, and sales for luxury real estate. Compass’s expansive presence encompasses 150 East 78th Street, 250 West 96th Street, 151 East 78th Street, 250 West 81st Street, and 378 West End Avenue, and portfolio-wide, its strategic advice has consistently yielded record-breaking results.
      </p>
    </TextContainer>
  ),
  "Branding": (
    <TextContainer>
      <TextTitle>Pandiscio Green</TextTitle>
      <TextSubTitle>branding</TextSubTitle>
      <p>
        Pandiscio Green is a New York based creative studio specializing in
        branding, graphic design, creative direction, and strategic marketing.
        Our work spans various categories — including luxury real estate,
        hospitality, publishing, art, and emerging brands. Our intimate team of
        designers has been entrusted by artists, architects, curators,
        restaurateurs, hoteliers, and other visionaries, to create the essential
        proof of concept that catalyzes their ideas and lays the groundwork for
        everything that will come next. German Architectural Digest anointed us
        “The father of the branded address” and the The New York Times described
        our method “Every [Pandiscio Green] project exists in its own
        elaborately constructed social and aesthetic universe.”
      </p>
    </TextContainer>
  ),
  "Imagery": (
    <TextContainer>
      <TextTitle>Grain</TextTitle>
      <TextSubTitle>Renderings</TextSubTitle>
      <p>
        Grain is a studio based in Shoreditch, London. They combine the science
        of architecture with the art of design to create CGI, interactive
        experiences and scale models.
      </p>
      <TextTitle>Recent Spaces</TextTitle>
      <TextSubTitle>architectual illustrations</TextSubTitle>
      <p>
        Recent Spaces is an industry-leading London-based 3D rendering agency,
        producing beautiful, accurate and engaging still 3D visualization CGI
        rendered images, animated films, illustrations, and Fluid Images for
        developers, architects, branding agencies, interior designers, product
        designers and artists, worldwide.
      </p>
    </TextContainer>
  ),
};
