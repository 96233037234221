import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ path, title, description, imageUrl }) {
  const GetSeoDataQuery = graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          url
        }
      }
    }
  `;

  const data = useStaticQuery(GetSeoDataQuery);
  const siteUrl = data.site.siteMetadata.url;

  return (
    <Helmet
      title={title}
      meta={[
        { name: "description", content: description },
        { property: "og:title", content: title },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        { property: "og:url", content: path ? `${siteUrl}/${path}` : siteUrl },
      ]}
    />
  );
}
export default SEO;
