import React from "react";
import styled from "styled-components";
import { Desktop, Tablet } from "../common/media";
import mobileImage from "../../images/InteriorCustom5Mobile.jpg";
import desktopImage from "../../images/InteriorCustom5Desktop.jpg";
import { SectionWrapper } from "../../components/common/Section";

export const TabletImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 34px);
  height: calc(100% - 32px);
  object-fit: cover;
`;

export const HeroWrapper = styled.section`
  position: relative;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
`;

const HeroAssetDesktopImage = styled.img`
  position: relative;
  top: 0;
  width: 100%;
  object-fit: cover;
`;

export const InteriorWithSideText = () => (
  <>
    <Desktop>
      <HeroWrapper>
        <HeroAssetDesktopImage src={desktopImage} />
      </HeroWrapper>
    </Desktop>
    <Tablet>
      <SectionWrapper>
        <TabletImage src={mobileImage} alt='outside bedroom balcony' />
      </SectionWrapper>
    </Tablet>
  </>
);
