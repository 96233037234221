import React from "react";
import styled from "styled-components";
import media from "../../../styles/media";
import TitleLine from "../../../images/TitleLine.png";

export const PageTitleCaption = styled.h1`
  position: absolute;
  z-index: 21;
  font: normal 16px "Calluna Regular";
  top: 90px;
  color: ${({ theme }) => theme.offColor};
  left: 50%;
  transform: translate(-50%, 0);

  &:before,
  &:after {
    content: "";
    background-color: transparent;
    background-image: url(${TitleLine});
    background-repeat: no-repeat;
    border: none;
    box-shadow: none;
    position: absolute;
    height: 20px;
    top: 10px;
    width: 20px;

    ${media.desktop`
      display: none;
    `}
  }

  &:before {
    left: -18px;
  }

  &:after {
    right: -26px;
  }

  ${media.desktop`
    top: 19px;
    left: 85px;
    letter-spacing: 0.75px;
  `}
`;

export const PageTitle = ({ pageTitle, className }) => {
  const styles =
    pageTitle === "Press"
      ? {
          display: "none",
        }
      : {};

  return (
    <PageTitleCaption style={styles} className={className}>
      {pageTitle}
    </PageTitleCaption>
  );
};
