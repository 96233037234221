import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import { SectionWrapper } from "../common/Section";
import bedroomTerrace from "../../images/PHMBedroomTerrace.jpg";
import bedroomTerraceSmall from "../../images/PHMT12.jpg";

const Desktop = styled.div`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;

  ${media.desktop`
    display: none;
  `}
`;

const TerraceWrapper = styled(SectionWrapper)`
  align-items: center;
  background-color: ${(props) => props.theme.offWhite};

  ${media.desktopLarge`
    padding: 50px 0;
    @media (min-height: 900px) {
      padding: 0;
    }
  `}
`;

const TerraceWrapperMobile = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.offWhite};
`;

const ImageAndTextContainer = styled.div`
  display: flex;
  position: relative;
  margin: 0 20px;
  flex-direction: row;

  ${media.desktop`
    margin: 0 10px 0 0;
  `}

  ${media.desktopLarge`
    margin: 0 10px 0 71px;
  `}
`;

const ImageWrapper = styled.div`
  z-index: 1;
  img {
    max-width: 100%;
  }
`;

const TextWrapper = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 18%;
    font: normal 15px/20px "Calluna Regular";
    margin-left: 40px;
    margin-right: 30px;
    margin-top: 8%;
    height: 200px;
  `}

  ${media.desktopMedium`
    margin-top: 14%;
  `}

  ${media.desktopLarge`
    margin-left: 56px;
    margin-top: 15%;
  `}
`;

const Border = styled.div`
  border-top: 1px solid black;
  width: 96px;
  margin: 10px 0;
`;

const TabletImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 34px);
  height: calc(100% - 32px);
  object-fit: cover;
`;

const DesktopLarge = styled.img`
  display: none;

  ${media.desktopLarge`
    display: block;
    width: 1392px;
    object-fit: contain;
  `}
`;

const DesktopSmall = styled.img`
  display: block;

  ${media.desktopLarge`
    display: none;
  `}
`;

const ImageCaption = styled.figcaption`
  position: absolute;
  font: 12px/20px "Calluna Italic";
  color: white;
  bottom: 2.8%;
  left: 1.2%;
  z-index: 3;
`;

export const PenthouseTerrace = () => (
  <>
    <Desktop>
      <TerraceWrapper>
        <ImageAndTextContainer>
          <ImageWrapper>
            <DesktopLarge src={bedroomTerrace} />
            <DesktopSmall src={bedroomTerraceSmall} />
          </ImageWrapper>
          <ImageCaption>
            Penthouse Master Bedroom with Private Terrace
          </ImageCaption>
          <TextWrapper>
            <Border />
            <p>
              Here’s what you find when you reach the very top: the city laid
              out below you, the sky and stars overhead, and room on all sides
              to see it all. Terraces and balconies extend from three sides on
              both floors, some large enough to host a dinner party, some
              intimate enough for sunbathing. The style is classic. The feel is
              abundant. The goal is enjoyment. The choice is yours.
            </p>
            <Border />
          </TextWrapper>
        </ImageAndTextContainer>
      </TerraceWrapper>
    </Desktop>
    <Tablet>
      <TerraceWrapperMobile>
        <TabletImage src={bedroomTerrace} alt='outside bedroom balcony' />
      </TerraceWrapperMobile>
    </Tablet>
  </>
);
