import React from "react";
import media from "../../styles/media";
import styled, { css } from "styled-components";
import {
  SectionWrapper,
  GutterAddresses,
} from "../../components/common/Section";
import { HeroWrapper } from "../SplitModule/ImageSplit";

const Desktop = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

const DesktopTextStyle = css`
  font: normal 14px "Calluna Regular";
  text-align: center;
  text-transform: capitalize;
`;

const LobbyWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.offWhite};
  align-items: center;
  justify-content: center;
  height: 150vh;
`;

const LobbyWrapperMobile = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.offWhite};
  height: 90vh;
  max-height: 90vh;
`;

export const HeroDesktop = styled.img`
  position: relative;
  top: 0;
  height: 100%;
  width: calc(100vw - 30%);
  left: calc(15.1% - 2px);

  object-fit: cover;

  ${media.desktop`
    height: 75vh;
  `}
`;

const TabletImage = styled.img`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 88.7%;
`;

const Title = styled.h1`
  position: absolute;
  bottom: 5px;
  left: calc(14.4% + 25px);
  ${DesktopTextStyle}
`;

const MobileTitle = styled(Title)`
  top: 79%;
  left: 10%;

  ${media.mobileLarge`
    top: 83%;
  `}
`;

export const InteriorLobbyModule = ({ desktopImage, mobileImage }) => (
  <>
    <Desktop>
      <LobbyWrapper>
        <HeroWrapper>
          <HeroDesktop src={desktopImage} />
          <Title>24-Hour Attended Lobby</Title>
        </HeroWrapper>
        <GutterAddresses />
      </LobbyWrapper>
    </Desktop>
    <Tablet>
      <LobbyWrapperMobile>
        <TabletImage src={mobileImage} />
        <MobileTitle>24-Hour Attended Lobby</MobileTitle>
      </LobbyWrapperMobile>
    </Tablet>
  </>
);
