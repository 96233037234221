import React, { useRef } from "react";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import media from "../../styles/media";
import kitchenOne from "../../images/k1.jpg";
import kitchenTwo from "../../images/k2.jpg";
import kitchenThree from "../../images/k3.jpg";
import whiteArrowNext from "../../images/CarouselWhiteArrowNext.svg";
import whiteArrowPrevious from "../../images/CarouselWhiteArrowPrevious.svg";
import WhiteArrowPreviousMobile from "../../images/WhiteArrowPreviousMobile.svg";
import WhiteArrowNextMobile from "../../images/WhiteArrowNextMobile.svg";

import BlackArrowNext from "../../images/BlackArrowNext.svg";
import BlackArrowPrevious from "../../images/BlackArrowPrevious.svg";
import BlackArrowNextMobile from "../../images/BlackArrow.svg";
import BlackArrowPreviousMobile from "../../images/BlackArrowPrevious.svg";

import { DotsWrapper } from "./HeroCarousel";
import { SectionWrapper, GutterAddresses } from "../common/Section";

const SliderWrapper = styled(SectionWrapper)`
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.offWhite};
`;

const SliderContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  margin: 0 auto;
  width: 80%;
  padding: 40px 26px 60px 26px;
  height: 60vw;
  max-height: 70vh;

  ${media.tablet`
     width: 90%;
     height: 70vw;
     padding: 75px 50px;
  `}

  ${media.desktopLarge`
    padding: 75px 150px 100px 150px;
    width: 1257px;
    height: 70vw;
  `}

  ${media.desktopMax`
    padding: 75px 150px 120px 150px;
    width: 1257px;
    height: 70vw;
  `}

  .slick-slider {
    height: 100%;
  }

  .slick-slide {
    min-width: 0;
    height: 100%;

    > div {
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      max-width: 100vw;
      min-width: 0;
      object-fit: cover;      
    }
  }

  .slick-list,
  .slider,
  .slick-track {
    min-width: 0;
    height: 100%;   
  }
`;

const Slide = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  width: 100vw;
  min-width: 0;
  height: 100%;
  background-color: ${props => props.theme.offWhite};
  &.is-portrait img {
    object-fit: contain !important;
  }
`;

const GutterAddressCarousel = styled.div`
  display: none;

  @media (min-width: 1530px) {
    display: block;
  }
`;

export const DotsWrapperLivingGallery = styled(DotsWrapper)`
  bottom: auto;

  li {
    .dot {
      top: 8px;
      border-color: ${(props) => props.theme.black};

      ${media.tablet`
        top: 40px;
      `}

      ${media.desktop`
        top: 20px;
      `}
    }
  }

  li.slick-active .dot {
    background-color: ${(props) => props.theme.black}
  }
`;

export const ArrowPrevious = styled.div`
  position: absolute;
  z-index: 10;
  top: 40.7%;
  right: 90%;
  cursor: pointer;
  display: none;

  ${media.mobileLarge`
    top: 47.9%;
    right: 92.5%;
    display: block;
  `}

  ${media.desktop`
    right: 97%;
  `}
`;

export const ArrowNext = styled.div`
  position: absolute;
  z-index: 10;
  top: 40.6%;
  left: 90%;
  cursor: pointer;
  display: none;

  ${media.mobileLarge`
    top: 47.9%;
    left: 92.5%;
    display: block;
  `}

  ${media.desktop`
    left: 96.5%;
  `}
`;

export const ImageDesktop = styled.img`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

export const ImageMobile = styled.img`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

export function LivingGalleryRoomCarousel ({ images }) {
  const slideRef = useRef();

  const settings = {
    dots: true,
    draggable: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapperLivingGallery>{dots}</DotsWrapperLivingGallery>,
    customPaging: () => <button className='dot' />,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  function PrevArrow() {
    return (
      <ArrowPrevious
        onClick={() => {
          slideRef.current.slickPrev();
        }}>
        <ImageDesktop src={BlackArrowPrevious} alt='previous' />
        <ImageMobile src={BlackArrowPreviousMobile} alt='previous' />
      </ArrowPrevious>
    );
  }

  function NextArrow() {
    return (
      <ArrowNext
        onClick={() => {
          slideRef.current.slickNext();
        }}>
        <ImageDesktop src={BlackArrowNext} alt='next' />
        <ImageMobile src={BlackArrowNextMobile} alt='next' />
      </ArrowNext>
    );
  }  

  return (
    <SliderWrapper>
      <SliderContainer>
        <Slider {...settings} ref={slideRef}>
          {images.map((image, i) => {
             const {width, height} = image.file.details.image             
             return (               
               <Slide
                   key={i}
                   className={height > width ? 'is-portrait' : 'is-landscape'}>
                 <img src={image.file.url} alt={image.title} />
               </Slide>          
             )             
           })}          
        </Slider>
      </SliderContainer>
      
      <GutterAddressCarousel>
        <GutterAddresses />
      </GutterAddressCarousel>
    </SliderWrapper>
  );
}
