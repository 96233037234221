import React from "react";
import styled from "styled-components";
import { SectionWrapper } from "../common/Section";
import { Desktop, Tablet } from "../common/media";
import { HeroWrapper } from "../Interiors/InteriorWithSideText";
import { HeroAssetDesktopImage } from "../Hero";

const HeroAssetTabletImage = styled.img`
  max-height: 100vh;
  height: 100%;
  width: 100%;
`;

export const ArchitectCustomModule = ({ desktopImage, mobileImage }) => (
  <>
    <Desktop>
      <SectionWrapper>
        <HeroWrapper>
          <HeroAssetDesktopImage
            src={desktopImage}
            alt='desktop image of building sketches'
          />
        </HeroWrapper>
      </SectionWrapper>
    </Desktop>
    <Tablet>
      <SectionWrapper>
        <HeroAssetTabletImage
          src={mobileImage}
          alt='mobile image of building sketches'
        />
      </SectionWrapper>
    </Tablet>
  </>
);
