// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amenities-js": () => import("./../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-architect-js": () => import("./../src/pages/architect.js" /* webpackChunkName: "component---src-pages-architect-js" */),
  "component---src-pages-availability-js": () => import("./../src/pages/availability.js" /* webpackChunkName: "component---src-pages-availability-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-films-js": () => import("./../src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiordesigner-js": () => import("./../src/pages/interiordesigner.js" /* webpackChunkName: "component---src-pages-interiordesigner-js" */),
  "component---src-pages-interiors-js": () => import("./../src/pages/interiors.js" /* webpackChunkName: "component---src-pages-interiors-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../src/pages/legal-privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-living-gallery-js": () => import("./../src/pages/living-gallery.js" /* webpackChunkName: "component---src-pages-living-gallery-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

