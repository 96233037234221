import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import media from "../../styles/media";
import { LegalModal } from "../../components/common/Modal/LegalModal";
import { PrivacyModal } from "../../components/common/Modal/PrivacyModal";
import styled, { createGlobalStyle } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const SliderWrapper = styled.section`
  position: relative;
  min-width: 0;
  height: 100vh;
  max-width: 100%;

  .slick-list,
  .slider,
  .slick-track {
    height: 100vh;
  }
`;

export const Slide = styled.div``;

export const DotsWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  list-style: none;
  z-index: 2000;

  ${media.desktop`
    bottom: 50%;
    left: 10px;
    transform: translate(-50%, 50%) rotate(90deg);
  `}

  ${media.desktopLarge`
    bottom: 47%;
  `}

  li {
    margin: 0 1.5px;

    button {
      padding: 4.5px;
    }

    button:before {
      display: none;
    }

    .dot {
      position: absolute;
      height: 2px;
      width: 2px;
      bottom: 42px;
      border-width: 1px;
      border-style: solid;
      border-color: ${(props) => getDotColor(props, true)};
      color: ${(props) => getDotColor(props)};
      border-radius: 6px;
    }

    &.slick-active .dot {
      background-color: ${(props) => getDotColor(props)};
      border-color: ${(props) => getDotColor(props)};
    }

    .whiteDot {
      position: absolute;
      height: 2px;
      width: 2px;
      bottom: 42px;
      border-width: 1px;
      border-style: solid;
      border-radius: 6px;
      border-color: ${(props) => getDotColor(props)};
      color: ${(props) => getDotColor(props, true)};
    }

    &.slick-active .whiteDot {
      background-color: ${(props) => getDotColor(props, true)};
      border-color: ${(props) => getDotColor(props, true)};
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  position: fixed;
  max-height: 100vh;
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 2000;
`;

const NoScroll = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

const getDotColor = ({ theme, isLegal }, invert = false) => {
  const condition = invert ? !isLegal : isLegal;
  return condition ? theme.black : theme.offWhite;
};

export function ModalCarousel({ closeModal }) {
  const [isLegal] = useState(true);

  const settings = {
    dots: true,
    draggable: false,
    arrows: false,
    vertical: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapper>{dots}</DotsWrapper>,
    customPaging: (isLegal) => {
      return isLegal ? (
        <button className='whiteDot' />
      ) : (
        <button className='dot' />
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          vertical: false,
        },
      },
    ],
  };

  const data = useStaticQuery(GetGlobalQuery);
  const globalContent = data.allContentfulGlobal.edges[0].node;
  const legalText = globalContent.legalDescription.childMarkdownRemark.html;
  const privacyText = globalContent.privacyDescription.childMarkdownRemark.html;

  return (
    <PageWrapper>
      <NoScroll />
      <SliderWrapper>
        <Slider {...settings}>
          <Slide>
            <LegalModal
              closeModal={closeModal}
              isLegal={isLegal}
              legalText={legalText}
            />
          </Slide>
          <Slide>
            <PrivacyModal closeModal={closeModal} privacyText={privacyText} />
          </Slide>
        </Slider>
      </SliderWrapper>
    </PageWrapper>
  );
}

export const GetGlobalQuery = graphql`
  query GetGlobalContent {
    allContentfulGlobal {
      edges {
        node {
          legalDescription {
            childMarkdownRemark {
              html
            }
          }
          privacyDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
