import React, { useState } from "react";
import styled from "styled-components";
import media from "../../styles/media";
import insta from "../../images/insta.png";
import { ModalCarousel } from "../../components/carousels/ModalCarousel";

import compassLogo from '../../images/COMPASS-DMG_Logo_Red.png'
import sopPdf from '../../pdfs/NY-StandardOperatingProcedures.pdf'

const FooterWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ bgColor }) => bgColor};
  padding-top: 21px;
  padding-bottom: 30px;

  ${media.desktop`
    flex-direction: row;
    padding: 32px 0px 59px 0px;
  `}
`;

const Contact = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    margin-left: 30px;
  `}

  ${media.desktopLarge`
    flex-wrap: nowrap;
    align-items: flex-start;
    flex-direction: row;
  `}
`;

const FooterNav = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;

  ${media.desktop`
    height: 20px;
    margin-right: 30px;
  `}

  ${media.desktopLarge`
    margin: 0;
    padding-right: 55px;
  `}
`;

const AddressContainer = styled.div`
  align-items: center;
  white-space: pre;
`;

const ContactInfo = styled(AddressContainer)`
  margin-left: 30px;

  ${media.desktopLarge`
    margin-left: 70px;
  `}
`;

const Sales = styled(AddressContainer)`
  margin-left: 30px;

  ${media.desktopLarge`
    margin-left: 70px;
  `}
`

const SocialContainer = styled.div`
  justify-content: space-between;
  white-space: pre;
  padding: 0 5px;
  margin: 0 10px;

  ${media.desktopLarge`
    width: 100px;
    flex: 1 1 100px;
    height: 100px;
  `}
`;

const Legal = styled(SocialContainer)`
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;

const ProceduresDesktop = styled.div`
  display: none;
  margin: 0 10px;
  padding: 0 5px;

  ${media.desktop`
    display: block;
  `}

  a {
    text-decoration: none;
  }
`

const LegalDesktop = styled(Legal)`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const LegalMobile = styled(Legal)`
  ${media.desktop`
    display: none;
  `}
`;

const ProceduresMobile = styled.div`
  a {
    text-decoration: none;
  }
`

const Social = styled(SocialContainer)`
  display: none;

  a {
    color: ${(props) => props.theme.oxBlood};
    text-decoration: none;
  }

  ${media.desktop`
    display: block;
    img {
      width: 20px;
      margin-bottom: -5px;
      margin-right: 3px;
    }
  `}
`;

const SocialMobile = styled(SocialContainer)`
  display: block;
  margin: 0 auto;
  width: auto;
  text-align: center;

  ${media.desktop`
    display: none;
  `}
`;

const FairHousing = styled(SocialContainer)`
  display: none;
  ${media.desktop`
    display: block;
    margin-right: 60px;

    a {
      color: ${(props) => props.theme.oxBlood};
      text-decoration: none;
    }
  `}
`;

const SocialIcon = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 26px;
`;

const Title = styled.h2`
  font-size: 14px;
  font-family: "Calluna Semibold";
  color: ${(props) => props.theme.oxBlood};
  text-transform: uppercase;
  letter-spacing: 4.8px;
  font-weight: normal;
`;

const SocialTitle = styled(Title)`
  letter-spacing: 1px;
`;

const SocialTitleMobile = styled(Title)`
  letter-spacing: 1px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5px;
  text-transform: none;

  address {
    font-style: normal;

    a {
      color: ${(props) => props.theme.oxBlood};
      text-decoration: none;
    }
  }
`;

const AddressContent = styled.div`
  margin: 24px 0 18px 0;

  p,
  address {
    margin: 0;
    font-family: "Calluna Light";
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 1.6px;
    font-style: normal;

    a {
      color: black;
      text-decoration: none;
    }
  }
`;

const Address = styled(AddressContent)``;

const AddressItemContent = styled(AddressContent)`
  margin-top: 36px;
`;

const EmailContent = styled(AddressContent)`
  margin-top: 52px;
`;

const AddressContentTitle = styled.h3`
  font-size: 14px;
  font-family: "Calluna Regular";
  margin: 0;
  font-weight: normal;
  letter-spacing: 1px;
`;

const PhoneLabel = styled(AddressContentTitle)``;

export const Footer = ({ bgColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      {isOpen && <ModalCarousel closeModal={toggleModal} />}

      <FooterWrapper bgColor={bgColor}>
        <Contact>
          <AddressContainer>
            <Title>Address</Title>
            <Address>
              <AddressContentTitle>Building Address:</AddressContentTitle>
              <address>
                <a
                  href='https://www.google.com/maps?cid=6820500356890213344&authuser=3&_ga=2.23714170.1513220372.1602689245-804037729.1602689245'
                  target='_blank'
                >
                  1228 Madison Avenue
                  <br />
                  New York, NY 10128
                </a>
              </address>
            </Address>
            <AddressItemContent>
              <AddressContentTitle>Sales Gallery:</AddressContentTitle>
              <address>
                <a
                  href='https://www.google.com/maps?cid=6820500356890213344&authuser=3&_ga=2.23714170.1513220372.1602689245-804037729.1602689245'
                  target='_blank'
                >
                  1228 Madison Avenue
                  <br />
                  New York, NY 10128
                </a>
              </address>
            </AddressItemContent>
          </AddressContainer>
          <ContactInfo>
            <Title>Contact</Title>
            <AddressContent>
              <PhoneLabel>Phone:</PhoneLabel>
              <address><a href="tel:212-828-2828">212.828.2828</a></address>
            </AddressContent>
            <EmailContent>
              <AddressContentTitle>Email:</AddressContentTitle>
              <address><a href="mailto:info@1228madison.com">info@1228madison.com</a></address>
            </EmailContent>
          </ContactInfo>
          <Sales>
            <img alt="Compass" src={compassLogo} />
            <p>Exclusive Sales and Marketing</p>
          </Sales>
        </Contact>
        <FooterNav>
          <LegalDesktop>
            <button onClick={() => setIsOpen(true)}>
              <Title>Legal &amp; Privacy Policy</Title>
            </button>
          </LegalDesktop>
          <FairHousing>
            <Title>
              <a
                href='https://www.dos.ny.gov/press/2020/docs/FairHousingNotice_new.pdf?j=465853&sfmc_sub=97792223&l=122_HTML&u=10033327&mid=100004597&jb=4'
                target='_blank'
              >
                Fair Housing & Equal Opportunity
              </a>
            </Title>
          </FairHousing>
          <ProceduresDesktop>
            <a href={sopPdf} target='_blank'>
              <Title>NY Standard Operating Procedures</Title>
            </a>
          </ProceduresDesktop>
          <Social>
            <SocialTitle>
              <a href="https://www.instagram.com/1228madison/" target="_blank">
                <img src={insta} alt='Instagram' />&nbsp;@1228Madison
              </a>
            </SocialTitle>
          </Social>
          <SocialMobile>
            <SocialTitleMobile>
              <address>
                <a
                  href='https://www.google.com/maps?cid=6820500356890213344&authuser=3&_ga=2.23714170.1513220372.1602689245-804037729.1602689245'
                  target='_blank'
                >
                  1228 Madison Avenue
                  <br />
                  New York, NY 10128
                </a>
              </address>
            </SocialTitleMobile>
            <SocialTitleMobile>
              <span>
                <address><a href="mailto:info@1228madison.com">info@1228madison.com</a></address>
              </span>
            </SocialTitleMobile>
            <SocialTitleMobile>
              <span>
                <address>Phone:<a href="tel:212-828-2828">212.828.2828</a></address>
              </span>
            </SocialTitleMobile>
            <SocialIcon>
              <a href="https://www.instagram.com/1228madison/" target="_blank">
                <img src={insta} alt='Instagram' />
              </a>
            </SocialIcon>
            <LegalMobile>
              <button onClick={() => setIsOpen(true)}>
                <Title>Legal &amp; Privacy</Title>
              </button>
            </LegalMobile>
            <ProceduresMobile>
              <a href={sopPdf} target="_blank">
                <Title>NY Standard Operating Procedures</Title>
              </a>
            </ProceduresMobile>
          </SocialMobile>
        </FooterNav>
      </FooterWrapper>
    </>
  );
};
