import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import media from "../../styles/media";
import whitex from "../../images/whitex.svg";
import logoImg from "../../images/LightMadison.svg";
import { Logo } from "../common/Header/MadisonLogo";
import { SectionWrapper, GutterAddresses } from "../common/Section";
import { Desktop, Tablet } from "../common/media";

const SuccessWrapper = styled(SectionWrapper)`
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.oxBlood};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.offWhite};
  padding-top: 15px;
  z-index: 3000;

  ${media.desktop`
    padding-top: 0;
  `}
`;

const TextContainer = styled.div`
  margin: 0;

  ${media.desktop`
    margin: 30px 0 40px 0;
  `}
`;

const Close = styled.button`
  position: absolute;
  z-index: 2000;
  top: 47px;
  right: 23px;
  transform: translate(-50%, 0);
  text-decoration: underline;
  background: none;
  color: ${(props) =>
    props.isLegal ? props.theme.offWhite : props.theme.black};
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  ${media.desktop`
    left: auto;
    transform: none;
    top: 69px;
    right: 50px;
  `}
`;

const SuccessTitle = styled.h1`
  font: normal 44px/43px "Saol Display Medium";
  margin-bottom: 10px;
  text-align: center;

  ${media.desktop`
    font-size: 73px;
    line-height: 70px;
    margin-bottom: 30px;
  `}
`;

const SuccessSubtitle = styled.h2`
  font: normal 18px/25px "Calluna Italic";
  text-align: center;
`;

const SuccessSubtitleMobile = styled.h2`
  font: normal 15px/15px "Calluna Italic";
  text-align: center;
`;

const Hr = styled.hr`
  width: 1px;
  border-style: solid;
  border-color: white;
  border-width: 83px 0 0 0;

  ${media.desktop`
    border-width: 200px 0 0 0;
    /* remove bottom line at 1000 height */
  @media (max-height: ${1000 / 16}em) {
    &.bot {
      display: none !important;
    }
  }

  /* remove both at 880 */
  @media (max-height: ${880 / 16}em) {
    display: none !important;
  }

`}

  /* Removes hightlight on tab highlight */
  outline: none;
`;

const LogoSmall = styled(Logo)`
  ${media.desktop`
    top: 40px;
    width: 168px;
  `}
`;
export const FormSuccess = ({ handleClose }) => {
  const [closeSuccess, setCloseSuccess] = useState(false);

  const node = useRef();

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (node.current.contains(target) || target.type === "button") {
        return;
      }
      setCloseSuccess(false);
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [closeSuccess]);
  return (
    <SuccessWrapper ref={node}>
      <LogoSmall alt="1228 Madison" src={logoImg} />
      <Close onClick={handleClose}>
        <img src={whitex} aria-hidden="true" alt="" />
      </Close>
      <Hr />
      <TextContainer>
        <SuccessTitle>Thank you</SuccessTitle>
        <Desktop>
          <SuccessSubtitle>
            A member of our team will be in touch soon
          </SuccessSubtitle>
        </Desktop>
        <Tablet>
          <SuccessSubtitleMobile>
            A member of our team will
            <br /> be in touch soon
          </SuccessSubtitleMobile>
        </Tablet>
      </TextContainer>
      <Hr />

      <GutterAddresses />
    </SuccessWrapper>
  );
};
