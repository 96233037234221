import { Link } from "gatsby";
import styled from "styled-components";
import media from "../../styles/media";
import { SectionWrapper } from "../common/Section";

export const BioWrapper = styled(SectionWrapper)`
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.oxBlood};
  padding: 60px 0 0 0;

  ${media.tablet`
    padding: 0;
  `}
`;

export const BioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${media.mobileMedium`
    top: -9%;
    margin-left: 5px;
  `}

  ${media.tablet`
    top: 0;
    margin-left: 0;
  `}

  ${media.desktop`
    flex-direction: row;
    margin-top: 10.5%;
    height: 500px;
  `}

  ${media.desktopLarge`
    margin-top: 0;
  `}
`;

export const BioBox = styled.div`
  display: flex;
  box-sizing: border-box;

  ${media.tablet`
    padding: 0;
  `}

  ${media.desktop`
    width: 42.5%;
    height: 350px;
  `}

  ${media.desktopMax`
    height: 510px;
  `}
`;

export const TitleContainer = styled.div`
  position: relative;
  left: 1%;

  ${media.tablet`
    left: 15%;
  `}

  ${media.desktop`
    left: 28%;
    border-right: 1px solid ${(props) => props.theme.oxBlood};
  `}
`;

export const TitleText = styled.h1`
  position: relative;
  font: normal 60px/55px "Saol Text Book";
  letter-spacing: 1.8px;
  margin: 0px 20px;
  padding-bottom: 36px;
  border-bottom: 1px solid ${(props) => props.theme.oxBlood};


  ${media.mobileSmall`
    width: 100px;
  `}

  ${media.mobileMedium`
    width: 235px;
  `}

  ${media.tablet`
    margin: 0;
    line-height: 68px;
    font-size: 75px;
    letter-spacing: 2.8px;
  `}

  ${media.desktop`
    padding-bottom: none;
    border-bottom: none;
    width: auto;
    top: 20%;
    right: 10%;
    width: 50%;
  `}

  ${media.desktopLarge`
    font-size: 157px;
    line-height: 144px;
    letter-spacing: 3.9px;
    top: 0;
  `}
`;

export const Description = styled(BioBox)`
  border-right: none;
  height: 369px;
  margin-top: 20px;

  ${media.tablet`
    height: none;
  `}
`;

export const BioParagraph = styled.div`
  position: relative;
  font: 14px/25px "Calluna Regular";
  padding: 12px 26px 0 26px;

  p {
    margin-top: 5px;
  }

  ${media.desktop`
    width: 90%;
    right: 2%;
    padding: 0;
  `}

  ${media.desktopLarge`
    width: 60%;
  `}
`;

export const BioLink = styled(Link)`
  font-family: "Calluna Regular";
  font-size: 14px;
  color: ${(props) => props.theme.oxBlood};
`;
