import React from "react";
import styled from "styled-components";
import media from "../../styles/media";

export const Wrapper = styled.section`
  padding: 0 30px 47px 30px;

  color: ${(props) => props.theme.oxBlood};
  background-color: ${({ bgColor }) => bgColor};

  ${media.desktop`
    display: flex;
    height: 348px;
    padding: 0 0 0 135px;
  `}
`;

export const Title = styled.h2`
  margin: 0;
  padding: 25px 0 0 0;

  font: normal 21px "Calluna";
  letter-spacing: 1.3px;

  ${media.desktop`
    padding: 35px 50px 0 0;
    width: 450px;
  `}
`;

export const Description = styled.p`
  margin: 0;
  padding-top: 28px;

  font: 12px/20px "Calluna Italic";
  letter-spacing: 1px;

  ${media.desktop`
    width: 450px;
    padding-top: 40px;
  `}
`;

export const FilmText = ({ children, bgColor }) => (
  <Wrapper bgColor={bgColor}>{children}</Wrapper>
);
