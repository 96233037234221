import React from "react";
import styled from "styled-components";
import { Layout } from "../styles/Layout";
import { useStaticQuery, graphql } from "gatsby";
import { DesignerBioPage } from "../components/BioModule/DesignerBio";
import { InteriorImageSplit } from "../components/SplitModule/InteriorImageSplit";
import { Hero } from "../components/Hero";
import { InteriorHeroCarousel } from "../components/carousels/InteriorHeroCarousel";
import { InteriorKitchenCarousel } from "../components/carousels/InteriorKitchenCarousel";
import { MasterBathCarousel } from "../components/carousels/MasterBathCarousel";
import LazyLoad from 'react-lazyload';
import interiorLandingHero from "../images/InteriorLandingHeroLiving.jpg";
import interiorLandingHeroMin from "../images/InteriorLandingHeroLiving-min.jpg";
import interiorLandingHeroMobile from "../images/InteriorLandingHeroLiving_mobile.jpg";
import interiorLandingHeroMobileMin from "../images/InteriorLandingHeroLiving_mobile-min.jpg";
import penthouseLivingRoom from "../images/PHLivingroom.jpg";
import privateTerrace from "../images/PenthouseKitchenTerraceDesktop.jpg";
import PTDesktop from "../images/2ndFloor-Desktop-10-27.jpg";
import PTMobile from "../images/2ndFloor-Mobile-10-27.jpg";
import { PowderRoom } from "../components/Interiors/PowderRoom";
import interiorLiving from "../images/GreatRoom.jpg";
import diningTable from "../images/GreatRoomDining.jpg";
import livingArea from "../images/GreatRoomLiving.jpg";
import { InteriorCenterText } from "../components/TextModule/InteriorCenterText";
import { InteriorCrownText } from "../components/TextModule/InteriorCrownText";
import { PenthouseKitchenModule } from "../components/Interiors/PenthouseKitchenModule";
import theme from "../styles/theme";
import { PenthouseTerrace } from "../components/Interiors/PenthouseTerrace";
import SEO from "../components/common/SEO";

const HideComponent = styled.span`
  display: none;
`;

const interiorPageTitle = "Interiors";

const bioDescription =
  "The chic interiors of 1228 Madison Avenue, designed by Kelly Behun Studio, complement the more traditional elements on the façade, but with a softer, lighter touch. Kelly’s eye for surprise and the unexpected results in a one-of-a-kind residential experience.";

const interiorImageSplitCaption =
  "The Townhouse Great Room with Private Terrace";

const InteriorsPage = () => {
  const data = useStaticQuery(InteriorQuery);
  const interiorPageContent = data.allContentfulInteriorPage.edges[0].node;
  const landingHeroCaption = interiorPageContent.landingHeroCaption;
  const firstDesktopTitle = interiorPageContent.firstDesktopTitle;
  const firstMobileTitle =
    interiorPageContent.firstMobileTitle.childMarkdownRemark.html;
  const firstCenterDescription =
    interiorPageContent.firstCenterDescription.firstCenterDescription;
  const secondHeroCaption = interiorPageContent.secondHeroCaption;
  const thirdHeroCaption = interiorPageContent.thirdHeroCaption;
  const secondDesktopTitle = interiorPageContent.secondDesktopTitle;
  const secondMobileTitle =
    interiorPageContent.secondMobileTitle.childMarkdownRemark.html;
  const secondCenterDescription =
    interiorPageContent.secondCenterDescription.secondCenterDescription;
  const title = interiorPageContent.title;
  const description = interiorPageContent.description;

  return (
    <Layout bgColor={theme.offWhite} pageTitle={interiorPageTitle}>
      <SEO title={title} description={description} path='/interior' />
      <Hero
        heroImageDesktop={interiorLandingHero}
        heroImageDesktopMin={interiorLandingHeroMin}
        leftCaption={landingHeroCaption}
        heroImageMobile={interiorLandingHeroMobile}
        heroImageMobileMin={interiorLandingHeroMobileMin}
        caption={landingHeroCaption}
        dark={true}
      />

      <LazyLoad offset={-100}>
        <InteriorCenterText
          desktopTitle={firstDesktopTitle}
          mobileTitle={firstMobileTitle}
          description={firstCenterDescription}
        />
        <InteriorImageSplit
          light={true}
          desktopImage={PTDesktop}
          mobileImage={PTMobile}
          imageCaption={interiorImageSplitCaption}
        />
        <InteriorHeroCarousel
          slideOne={interiorLiving}
          slideTwo={diningTable}
          slideThree={livingArea}
          bgColor={theme.offWhite}
        />
        <InteriorKitchenCarousel />
        <MasterBathCarousel />
        <PowderRoom />
        {/* <HideComponent>
          <Hero
            heroImageDesktop={privateTerrace}
            leftCaption={secondHeroCaption}
            heroImageMobile={privateTerrace}
            caption={secondHeroCaption}
          />
          <InteriorCrownText
            desktopTitle={secondDesktopTitle}
            mobileTitle={secondMobileTitle}
            description={secondCenterDescription}
          />
          <PenthouseKitchenModule />
          <Hero
            heroImageDesktop={penthouseLivingRoom}
            leftCaption={thirdHeroCaption}
            heroImageMobile={penthouseLivingRoom}
            caption={thirdHeroCaption}
          />
          <PenthouseTerrace />
        </HideComponent> */}
        <DesignerBioPage bioDescription={bioDescription} />
      </LazyLoad>
    </Layout>
  );
};
export default InteriorsPage;

const InteriorQuery = graphql`
  query GetInteriorPage {
    allContentfulInteriorPage {
      edges {
        node {
          title
          description
          landingHeroCaption
          firstDesktopTitle
          firstMobileTitle {
            childMarkdownRemark {
              html
            }
          }
          firstCenterDescription {
            firstCenterDescription
          }
          secondHeroCaption
          thirdHeroCaption
          secondDesktopTitle
          secondMobileTitle {
            childMarkdownRemark {
              html
            }
          }
          secondCenterDescription {
            secondCenterDescription
          }
        }
      }
    }
  }
`;
