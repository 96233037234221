import React from "react";
import styled from "styled-components";
import { Layout } from "../styles/Layout";
import { useStaticQuery, graphql } from "gatsby";
import { BioSplit } from "../components/SplitModule/BioSplit";
import { ArchitectImageSplit } from "../components/Architect/ArchitectImageSplit";
import ArchitectCustomModule from "../components/Architect/ArchitectCustomModule";
import { HeroCarousel } from "../components/carousels/HeroCarousel";
import { ArchitectMaterialCarousel } from "../components/carousels/ArchitectMaterialCarousel";
import { Hero } from "../components/Hero";
import robertImage from "../images/RamsaTeam.jpg";
import robertImageMobile from "../images/RAMSA-Portrait-mobile.jpg";
import customArchitect from "../images/Building-Sketches-Desktop.jpg";
import customArchitectMobile from "../images/Building-Sketches-Mobile1.jpg";
import easternFacade from "../images/CentralParkFacade.jpg";
import entrancePhoto from "../images/EntrancePhoto.jpg";
import chestnutHouse from "../images/ChestnutHouse.jpg";
import prestonHollow from "../images/PrestonHollow.jpg";
import personDrawing from "../images/PersonDrawingDesktop.jpg";
import theme from "../styles/theme";
import SEO from "../components/common/SEO";

const HideComponent = styled.span`
  display: none;
`;

const architectPageTitle = "Architect";

const slideOneCaption = "15 Central Park West";
const slideTwoCaption = "The Chatham";
const slideThreeCaption = "House in Chestnut Hill";
const slideFourCaption = "House in Preston Hollow";

const Architect = () => {
  const data = useStaticQuery(ArchitectQuery);
  const architectPageContent = data.allContentfulArchitectPage.edges[0].node;
  const imageBioTitle = architectPageContent.imageBioTitle;
  const imageBioDescription =
    architectPageContent.imageBioDescription.childMarkdownRemark.html;
  const title = architectPageContent.title;
  const description = architectPageContent.description;

  return (
    <Layout
      bgColor={theme.merino}
      pageTitle={architectPageTitle}
      menucolor={theme.merino}
    >
      <SEO title={title} description={description} path='/architect' />
      <BioSplit
        description={imageBioDescription}
        desktopImage={robertImage}
        title={imageBioTitle}
        mobileImage={robertImageMobile}
        mobileBio={imageBioDescription}
        isFirst={true}
      />
      <HeroCarousel
        slideOne={easternFacade}
        slideOneCaption={slideOneCaption}
        slideTwo={entrancePhoto}
        slideTwoCaption={slideTwoCaption}
        slideThree={chestnutHouse}
        slideThreeCaption={slideThreeCaption}
        slideFour={prestonHollow}
        slideFourCaption={slideFourCaption}
        light={true}
        lightCaption={true}
        bgColor={theme.merino}
      />
      <ArchitectImageSplit light={true} />
      <HideComponent>
        <ArchitectMaterialCarousel />
      </HideComponent>

      <Hero
        heroImageDesktop={personDrawing}
        heroImageDesktopMin={personDrawing}
        heroImageMobile={personDrawing}
        heroImageMobileMin={personDrawing}
        isSquare={true}
      />
    </Layout>
  );
};

export default Architect;

export const ArchitectQuery = graphql`
  query GetArchitectQuery {
    allContentfulArchitectPage {
      edges {
        node {
          title
          description
          imageBioTitle
          imageBioDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
