import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import {
  ImageWrapper,
  HeroWrapper,
  HeroAssetDesktopImage,
  ImageCaption,
} from "./ImageSplit";

const Desktop = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

const InteriorImageCaption = styled(ImageCaption)`
  bottom: -9px;
  left: 7%;
  position: absolute;
  z-index: 1001;

  @media only screen and (max-height: 835px) {
    color: white;
    left: 8%;
    bottom: 18px;
  }
`;

const HeroAssetTabletImage = styled.img`
  width: 100%;

  ${media.tablet`
    max-height: 100vh;
    height: 100%;
  `}
`;

export const InteriorImageSplit = ({
  light,
  desktopImage,
  mobileImage,
  imageCaption,
}) => (
  <>
    <Desktop>
      <ImageWrapper light={light}>
        <HeroWrapper>
          <HeroAssetDesktopImage src={desktopImage} />
          {imageCaption && (
            <InteriorImageCaption>{imageCaption}</InteriorImageCaption>
          )}
        </HeroWrapper>
      </ImageWrapper>
    </Desktop>
    <Tablet>
      <ImageWrapper>
        <HeroAssetTabletImage src={mobileImage} />
      </ImageWrapper>
    </Tablet>
  </>
);
