import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import designerOne from "../../images/Fireplace.png";
import designerTwo from "../../images/Study-bar-sketch-Desktop.jpg";
import designerTwoMobile from "../../images/Study-bar-sketch-Mobile.jpg";
import { SectionWrapper } from "../common/Section";
import { Desktop, Tablet } from "../../components/common/media";

const StudySectionWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.merino};
  height: 65vh;
`;

const FireplaceSectionWrapper = styled(SectionWrapper)`
  background-color: #f6efe0;
  height: 50vh;
  align-items: center;
  justify-content: center;
`;

const DesignContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const SketchBoxLight = styled.div`
  display: flex;
  width: 100vw;
  height: 60vh;
  align-items: center;
  justify-content: center;
  background-color: #f6efe0;
  color: black;

  ${media.desktop`
    width: 50vw;
    height: 100vh;
  `}
`;

const SketchBoxDark = styled(SketchBoxLight)`
  background-color: ${(props) => props.theme.merino};
`;

const FirePlaceContainer = styled.div`
  height: 50%;
`;

const FirstImageSketch = styled.img`
  display: block;
  max-width: 100%;
  height: 60%;

  ${media.desktop`
    height: 40%;
  `}
`;

const ImageSketchDesktop = styled.img`
  display: block;
  max-width: 100%;
  height: 80%;

  ${media.desktop`
    height: 70%;
    max-width: 100%;
  `}
`;

const ImageSketchMobile = styled(ImageSketchDesktop)`
  height: auto;
`;

const ImageCaption = styled.figcaption`
  position: absolute;
  font-family: "Calluna Italic";
  font-size: 10px;
  top: 92%;
  left: 46.5%;

  ${media.desktop`
    top: 73%;
    left: 23%;
  `}
`;

const ImageCaptionMobile = styled(ImageCaption)`
  display: block;
  top: 90%;
  left: 50%;
  transform: translate(-50%, 0);

  ${media.mobile`
    display: none;
  `}
`;

export const DesignerCustom = () => (
  <>
    <Desktop>
      <SectionWrapper>
        <DesignContainer>
          <SketchBoxLight>
            <FirstImageSketch src={designerOne} />
            <ImageCaption>
              Process Sketch from <br /> Kelly Behun Studio
            </ImageCaption>
          </SketchBoxLight>
          <SketchBoxDark>
            <ImageSketchDesktop src={designerTwo} />
          </SketchBoxDark>
        </DesignContainer>
      </SectionWrapper>
    </Desktop>
    <Tablet>
      <StudySectionWrapper>
        <ImageSketchMobile src={designerTwoMobile} />
      </StudySectionWrapper>
      <FireplaceSectionWrapper>
        <FirePlaceContainer>
          <ImageSketchMobile src={designerOne} />
          <ImageCaptionMobile>
            Process Sketch from Kelly Behun Studio
          </ImageCaptionMobile>
        </FirePlaceContainer>
      </FireplaceSectionWrapper>
    </Tablet>
  </>
);
