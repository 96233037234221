import React from "react";
import theme from "../styles/theme";
import { Layout } from "../styles/Layout";
import { Teams } from "../components/Teams/Teams";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/common/SEO";

const pageTitle = "Team";

const TeamPage = () => {
  const data = useStaticQuery(TeamQuery);
  const teamContent = data.allContentfulTeam.edges[0].node;
  const title = teamContent.title;
  const description = teamContent.description;

  return (
    <Layout
      bgColor={theme.offWhite}
      menucolor={theme.merino}
      pageTitle={pageTitle}
    >
      <SEO title={title} description={description} path='/team' />
      <Teams />
    </Layout>
  );
};
export default TeamPage;

export const TeamQuery = graphql`
  query GetTeamPage {
    allContentfulTeam {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;
