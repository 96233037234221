import React from "react";
import { Layout } from "../styles/Layout";
import { FilmText, Title, Description } from "../components/Films/FilmText";
import { Desktop } from "../components/common/media";
import { Video } from "../components/Films/Video";
import theme from "../styles/theme";
import TimelessStrollPoster from "../images/TimelessStrollPoster.jpg";
import Video1Poster from "../images/Video1Poster.jpg";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/common/SEO";

const Films = () => {
  const data = useStaticQuery(FilmsQuery);
  const filmsContent = data.allContentfulFilms.edges[0].node;
  const title = filmsContent.title;
  const description = filmsContent.description;

  return (
    <Layout menucolor={theme.merino} bgColor={theme.merino} pageTitle='Films'>
      <SEO title={title} description={description} path='/films' />
      <Video poster={Video1Poster}>
        <source
          src='https://player.vimeo.com/external/412353079.hd.mp4?s=0223d65b20f50376a7762c77d7ec084af6b28fff&profile_id=175'
          type='video/mp4'
          media='all and (min-width:501px)'
        />
        <source
          src='https://player.vimeo.com/external/412353079.sd.mp4?s=17eaba9ad82d62c5411061e15c758bee9d757b82&profile_id=164'
          type='video/mp4'
          media='all and (max-width:500px)'
        />
        Your browser does not support the video tag.
      </Video>
      <FilmText bgColor={theme.merino}>
        <Title>
          Robert A.M. Stern <Desktop>Architects</Desktop>
          <br />
          on 1228 Madison Avenue
        </Title>
        <Description>
          Robert A.M. Stern, Paul Whalen, and Michael Jones–the creators of 1228
          Madison Avenue–take you behind the curtain to reveal a bit of the
          process and inspiration behind creating this new Classic New York
          residence.
        </Description>
      </FilmText>

      <Video poster={TimelessStrollPoster}>
        <source
          src='https://player.vimeo.com/external/411593989.hd.mp4?s=ba0c821e55f7d637b9b51368a71b6f4c6b4bc813&profile_id=175'
          type='video/mp4'
          media='all and (min-width:501px)'
        />
        <source
          src='https://player.vimeo.com/external/411593989.sd.mp4?s=d291ca112a06ba9ecb4482f513191cf713aa01cf&profile_id=164'
          type='video/mp4'
          media='all and (max-width:500px)'
        />
        Your browser does not support the video tag.
      </Video>

      <FilmText bgColor={theme.offWhite}>
        <Title>A Timeless Stroll</Title>
        <Description>
          Take a stroll with Paul Whalen and Kelly Behun around the historical
          and culture-rich Upper East Side. Kelly and Paul discuss their
          favorite moments and sources of inspiration located in 1228 Madison’s
          iconic neighborhood.
        </Description>
      </FilmText>
    </Layout>
  );
};

export default Films;

export const FilmsQuery = graphql`
  query Films {
    allContentfulFilms {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;
