import React from "react";
import styled from "styled-components";
import { Layout } from "../styles/Layout";
import { useStaticQuery, graphql } from "gatsby";
import { BioSplit } from "../components/SplitModule/BioSplit";
import { HeroCarousel } from "../components/carousels/HeroCarousel";
import { DesignerMaterialCarousel } from "../components/carousels/DesignerMaterialCarousel";
import { DesignerCustom } from "../components/InteriorDesigner/DesignerCustom";
import { DesignerLobbyCustom } from "../components/InteriorDesigner/DesignerLobbyCustom";
import kellyBehun from "../images/Kelly-Portrait-Desktop.jpg";
import kellyMobile from "../images/Kelly-Portrait-mobile.jpg";
import parkAvePH from "../images/ParkAvePH.jpg";
import roundDining from "../images/RoundDining1.jpg";
import hamptonsHome from "../images/HamptonsHome.jpg";
import hamptonsHome2 from "../images/hamptonsHome2.jpg";
import theme from "../styles/theme";
import SEO from "../components/common/SEO";

const HideComponent = styled.span`
  display: none;
`;

const architectPageTitle = "Interior Designer";

const slideOneCaption = "Park Avenue Penthouse";
const slideTwoCaption = "Upper East Side Penthouse";
const slideThreeCaption = "Hamptons Home";
const slideFourCaption = "Hamptons Home";

const InteriorDesigner = () => {
  const data = useStaticQuery(InteriorDesignerQuery);
  const interiorDesignerContent =
    data.allContentfulInteriorDesignerPage.edges[0].node;
  const imageBioTitle = interiorDesignerContent.imageBioTitle;
  const imageBioDescription =
    interiorDesignerContent.imageBioDescription.imageBioDescription;
  const title = interiorDesignerContent.title;
  const description = interiorDesignerContent.description;

  return (
    <Layout
      bgColor={theme.offWhite}
      pageTitle={architectPageTitle}
      menucolor={theme.offWhite}
    >
      <SEO title={title} description={description} path='/interiordesigner' />
      <BioSplit
        mobileImage={kellyMobile}
        desktopImage={kellyBehun}
        description={imageBioDescription}
        mobileBio={imageBioDescription}
        title={imageBioTitle}
        light={true}
        isFirst={true}
      />
      <HeroCarousel
        slideOne={parkAvePH}
        slideOneCaption={slideOneCaption}
        slideTwo={roundDining}
        slideTwoCaption={slideTwoCaption}
        slideThree={hamptonsHome}
        slideThreeCaption={slideThreeCaption}
        slideFour={hamptonsHome2}
        slideFourCaption={slideFourCaption}
        lightCaption={true}
        bgColor={theme.offWhite}
      />
      <HideComponent>
        <DesignerMaterialCarousel />
      </HideComponent>
      <DesignerLobbyCustom />
      <DesignerCustom />
    </Layout>
  );
};

export default InteriorDesigner;

const InteriorDesignerQuery = graphql`
  query GetInteriorDesignerQuery {
    allContentfulInteriorDesignerPage {
      edges {
        node {
          title
          description
          imageBioTitle
          imageBioDescription {
            imageBioDescription
          }
        }
      }
    }
  }
`;
