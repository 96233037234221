import React, { useState } from "react";
import { Layout } from "../styles/Layout";
import SEO from '../components/common/SEO';
import theme from "../styles/theme";
import { Hero } from "../components/Hero";
import styled from 'styled-components'
import media from "../styles/media";
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import Room from '../components/LivingGallery/Room'
import smoothscroll from 'smoothscroll-polyfill';

import {
  LifestyleGalleryCenterText
} from "../components/TextModule/LifestyleGalleryCenterText";


const RoomsContainer = styled.div`
  text-align: center;
  padding: 100px 0;
  background-color: ${(props) => props.theme.offWhite};
`;

const ArtistsCredits = styled.div`
  text-align: center;
  padding: 0px 30px 50px 30px;
  background-color: ${(props) => props.theme.offWhite};

  > div {
    font-family: 'Calluna Regular';    
    margin: 0 auto;
    max-width: 1000px;
    font-size: 15px;
  }

  h5 {
    font-family: 'Calluna Semibold';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: .2em;
    margin: 0 0 2em 0;
  }

  ol,ul {
    margin: 0;
    padding: 0;
    display: inline-block;
  }
`;

const Gallery = ({ data }) => {
  let {
    title,
    heroImage,
    heroImageMobile,
    introHeadline,
    introHeadlineMobile,
    introBody,
    rooms,
    artistsCredits
  } = data.allContentfulLivingGallery.edges[0].node

  let [roomOpen, setRoomOpen] = useState(-1)
  
  return (
    <Layout bgColor={theme.merino} pageTitle={title}>
      <SEO title={''} description={''} path='/living-gallery' />
      <Hero
          heroImageMobile={heroImageMobile.file.url}
          heroImageDesktop={heroImage.file.url}
          isSquare={typeof window !== 'undefined' && window.innerWidth < 768}
          dark={true} />
      
      <LifestyleGalleryCenterText
          desktopTitle={introHeadline}
          mobileTitle={introHeadlineMobile.childMarkdownRemark.html}
          description={introBody.introBody} />
      
      <RoomsContainer>
        {rooms.map((room, i) => {
           return (
             <Room
                 isOpen={roomOpen == i}
                 onTitleClick={() => {
                     smoothscroll.polyfill()
                     setRoomOpen(roomOpen == i ? -1 : i)
                   }}
                 key={i}
                 {...room}
             />
           )
         })}
      </RoomsContainer>
      <ArtistsCredits>
        <h5>Artists Credits</h5>
        <div dangerouslySetInnerHTML={{ __html: artistsCredits.childMarkdownRemark.html }}></div>
      </ArtistsCredits>
    </Layout>
  );
};

export default Gallery

export const query = graphql`
  query LivingGalleryQuery {
    allContentfulLivingGallery {
      edges {
        node {
          title, 
          heroImage {
            description
            title
            file {
              url
            }
          }, 
          heroImageMobile {
            description
            title
            file {
              url
            }
          }, 
          introHeadline, 
          introHeadlineMobile {
            childMarkdownRemark {
              html
            }
          }, 
          introBody {
            introBody
          }, 
          rooms {
            id, 
            title, 
            longTitle, 
            description {
              description
            },
            carousel {
              id, 
              title,
              file {
                url 
                details {
                  image {
                    width
                    height
                  }
                }
              }              
            }, 
            items {
              id
              title
              origin
              externalURL
              description {
                description
              }
              image {
                file {
                  url
                }
                title
              }
            }
          }
          artistsCredits {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
