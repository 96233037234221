import React from "react";
import styled, { css } from "styled-components";
import ProgressiveImage from "react-progressive-graceful-image"
import media from "../styles/media";
import { Desktop, Tablet } from "./common/media";
import { SectionWrapper } from "../components/common/Section";

const TextStyles = css`
  font-family: "Calluna Italic";
  font-weight: normal;
  font-size: 14px;
  color: ${(props) => (props.dark ? props.theme.black : props.theme.offWhite)};
`;

export const HeroWrapper = styled(SectionWrapper)`
  max-height: ${(props) => (props.isSquare ? "500px" : "100vh")};

  ${media.desktop`
    max-height: 100vh;
  `}

  img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    max-height: ${(props) => (props.isSquare ? "500px" : "100vh")};
    ${media.desktop`
      max-height: 100vh;
    `}
  }
`;

const ImageCaption = styled.figcaption`
  z-index: 1;
  margin-top: 0;
  ${TextStyles};
`;

export const ImageCaptionLeft = styled(ImageCaption)`
  display: none;

  ${media.desktop`
    display: flex;
    align-items: flex-end;
    margin-left: 29px;
    margin-bottom: 22px;
  `}
`;

const ImageCaptionRight = styled(ImageCaption)`
  position: absolute;
  bottom: 7px;
  right: 11px;

  ${media.desktop`
    position: relative;
    display: flex;
    align-items: flex-end;
    left: 30px;
    bottom: 28px;
 `}
`;

const ImageCaptionCenter = styled(ImageCaption)`
  position: absolute;
  bottom: 19px;
  right: 11px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  padding: 0 30px;

  ${media.tablet`
    display: flex;
    align-items: flex-end;
    bottom: 28px;
    right: 31px;
    padding: 0;
    -webkit-transform: translateX(-100.2%);
    transform: translateX(-100.2%);
 `}
`;

export const Hero = ({
  heroImageDesktop,
  heroImageDesktopMin,
  heroImageMobile,
  heroImageMobileMin,
  leftCaption,
  rightCaption,
  centerCaption,
  dark,
  isSquare,
  caption,
}) => (
  <HeroWrapper isSquare={isSquare}>
    <Desktop>
      <ProgressiveImage src={heroImageDesktop} placeholder={heroImageDesktopMin}>
        {(src) => <img src={src} alt={caption} />}
      </ProgressiveImage>
    </Desktop>
    <Tablet>
      <ProgressiveImage src={heroImageMobile} placeholder={heroImageMobileMin}>
        {(src) => <img src={src} alt={caption} />}
      </ProgressiveImage>
    </Tablet>
    {leftCaption && (
      <ImageCaptionLeft dark={dark}>{leftCaption}</ImageCaptionLeft>
    )}
    {rightCaption && <ImageCaptionRight>{rightCaption}</ImageCaptionRight>}
    {centerCaption && <ImageCaptionCenter>{centerCaption}</ImageCaptionCenter>}
  </HeroWrapper>
);
