import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import {
  BioContainer,
  BioWrapper,
  BioBox,
  TitleText,
  TitleContainer,
  Description,
  BioParagraph,
  BioLink,
} from "./Bio";

const DesignerWrapper = styled(BioWrapper)`
  background-color: ${(props) => props.theme.merino};
`;

const DesignerBioBox = styled(BioBox)``;

const DesignerTitleContainer = styled(TitleContainer)`
  ${media.tablet`
    left: 3%;
  `}

  ${media.desktop`
    left: 48%;
  `}

  ${media.desktopLarge`
    left: 33%;
  `}

  ${media.desktopMax`
    left: 57%;
  `}
`;

const DesignerTitle = styled(TitleText)`
  ${media.desktop`
    line-height: 72px;
    right: 48%;
  `}

  ${media.desktopLarge`
    line-height: 144px;
    right: 32%;
  `}

  ${media.desktopMax`
    right: 30%;
  `}
`;

const DesignerDescription = styled(Description)``;

const DesignerBio = styled(BioParagraph)`
  ${media.desktop`
    width: 90%;
    right: 10%;
    padding: 0;
    top: 3%;
  `}

  ${media.desktopLarge`
    width: 80%;
    top: 10%;
    right: 1%;
  `}

  ${media.desktopMax`
    top: 5%;
    width: 60%;

    @media (min-height: 700px) {
      left: 10%;
    }
    @media (min-height: 900px) {
      left: 0;
      right: 2%;
    }
  `}
`;

const DesignerLinkContainer = styled.div`
  margin-top: 30px;
`;

const InteriorLink = styled(BioLink)``;

export const DesignerBioPage = ({ bioDescription }) => (
  <DesignerWrapper>
    <BioContainer>
      <DesignerBioBox>
        <DesignerTitleContainer>
          <DesignerTitle>
            Kelly
            <br />
            Behun
            <br />
            Studio
          </DesignerTitle>
        </DesignerTitleContainer>
      </DesignerBioBox>
      <DesignerDescription>
        <DesignerBio>
          {bioDescription}
          <br />
          <DesignerLinkContainer>
            <InteriorLink to='/interiordesigner'>
              More about the Interior Designer
            </InteriorLink>
          </DesignerLinkContainer>
        </DesignerBio>
      </DesignerDescription>
    </BioContainer>
  </DesignerWrapper>
);
