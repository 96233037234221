import React from "react";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import media from "../../styles/media";
import facade from "../../images/Hero-mobile-carousel.jpg";
import sketchImage from "../../images/HeroSketch-Carousel-mobile.jpg";
import { SectionWrapper, GutterAddresses } from "../common/Section";
import { DotsWrapper } from "./HeroCarousel";

export const ArchitectWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.offWhite};
  color: black;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 100%;
  min-height: 120vh;
  height: 100vh;
  max-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const SliderContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 0;
  height: 95vh;
  max-width: 100%;
  max-height: 95vh;
  margin: 0 auto;
  width: 90vw;
  margin: 50px 0;

  ${media.tablet`
    height: 588px;
    margin-top: 0;
    width: 440px;
  `}

  .slick-slide {
    min-width: 0;

    img {
      margin: 0 auto;
      height: 100%;
      max-height: 95vh;
      min-width: 0;
      width: 100%;
      max-width: 100vw;
      justify-content: center;
      align-items: center;
      object-fit: cover;

      ${media.tablet`
        max-height: 95vh;
        height: 588px;
      `}
    }
  }

  .slick-list,
  .slider,
  .slick-track {
    height: 100vh;
    min-width: 0;
    max-height: 100vh;

    ${media.tablet`
      max-height: 95vh;
    `}
  }
`;

const Slide = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  min-width: 0;
`;

const CaptionStyles = css`
  display: flex;
  position: absolute;
  z-index: 5;
  font: normal 12px/16px "Calluna Italic";
`;

const ImageCaption = styled.figcaption`
  ${CaptionStyles}
  bottom: 10%;
  left: 49%;
  transform: translate(-50%, 0);
  text-align: center;

  ${media.tablet`
    left: auto;
    transform: translate(0);
    right: 10px;
    bottom: 24%;
    text-align: left;
    color: white;
    width: 15%;
  `}
`;

const DotsWrapperMaterial = styled(DotsWrapper)`
  li {
    .dot {
      color: black;
      border: 1px solid black;
      bottom: 25px;

      ${media.tablet`
        bottom: -20px;
      `}
    }

    &.slick-active .dot {
      background-color: black;
    }
  }
`;

export function ArchitectMobileCarousel() {
  const settings = {
    dots: true,
    draggable: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapperMaterial>{dots}</DotsWrapperMaterial>,
    customPaging: () => <button className='dot' />,
  };

  return (
    <ArchitectWrapper>
      <SliderContainer>
        <Slider {...settings}>
          <Slide>
            <img src={facade} alt='building facade' />
          </Slide>
          <Slide>
            <img src={sketchImage} alt='building sketch' />
            <ImageCaption>
              Process Sketch by <br /> Robert A.M. Stern Architects
            </ImageCaption>
          </Slide>
        </Slider>
      </SliderContainer>
      <GutterAddresses />
    </ArchitectWrapper>
  );
}
