import React from "react";
import styled, { css } from "styled-components";
import { GutterAddresses } from "../common/Section";
import {
  ImageWrapper,
  HeroWrapper,
  HeroAssetDesktopImage,
} from "../SplitModule/ImageSplit";
import powderDesktop from "../../images/interiors-powder-room.jpg";
import media from "../../styles/media";
import powderMobile from "../../images/Secondary-bath-mobile.jpg";
import secondPowderMobile from "../../images/Powder-room-mobile.jpg";

const Desktop = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

const Tablet = styled.div`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

const HeaderText = css`
  font: normal 32px/32px "Saol Text Book";

  ${media.desktopLarge`
    font-size: 44px;
    line-height: 45px;
  `}
`;

const ParaText = css`
  font: 14px/19px "Calluna Italic";
  color: black;
  text-align: left;

  ${media.desktop`
    font-size: 10px;
    line-height: 12px;
  `}
`;

const PowderWrapper = styled(ImageWrapper)`
  background-color: white;
`;

const PowderWrapperMobile = styled(PowderWrapper)`
  max-height: 90vh;
  height: 90vh;
  padding-bottom: 100px;
`;

const SecondaryTitle = styled.h1`
  position: absolute;
  bottom: 18.5%;
  left: 10.5%;
  top: 71%;
  margin: 0;
  text-transform: capitalize;
  ${HeaderText}

  ${media.desktopLarge`
    top: 70.5%;
  `}
`;

const SecondaryTitleMobile = styled(SecondaryTitle)`
  font-size: 35px;
`;

const PowderTitle = styled.h1`
  position: absolute;
  text-transform: capitalize;
  top: 71%;
  left: auto;
  margin: 0;
  right: 12.7%;
  bottom: 18.5%;
  ${HeaderText}

  ${media.desktopLarge`
    top: 70.5%;
    right: 10.5%;
  `}
`;

const PowderTitleMobile = styled(PowderTitle)`
  font-size: 35px;
`;

const SecondaryCaption = styled.div`
  position: absolute;
  top: 76%;
  padding: 30px;
  width: 170px;

  p {
    ${ParaText}
  }

  ${media.tablet`
    left: 7.5%;
  `}

  ${media.desktopMedium`
    left: 8.5%;
  `}

  ${media.desktopLarge`
    top: 77%;
  `}
`;

const SecondaryCaptionMobile = styled(SecondaryCaption)`
  width: 190px;
  margin-top: 15px;
  padding: 30px 0 0 0;
`;

const PowderCaption = styled(SecondaryCaption)`
  top: 76%;
  left: auto;
  right: 5.8%;

  ${media.tablet`
    right: 2%;
    left: auto;
  `}

  ${media.desktop`
    right: 5%;
  `}

  ${media.desktopMedium`
    right: 8.5%;
    width: 10%;
    left: auto;
  `}

  ${media.desktopLarge`
    top: 77%;
    right: 7.7%;
    width: 10%;
  `}

  ${media.desktopMax`
    right: 7%;
  `}
`;

const PowderCaptionMobile = styled(PowderCaption)`
  font: 14px/19px "Calluna Italic";
  margin-right: -90px;
  margin-top: 20px;
`;

const HeroAssetTabletImage = styled.img`
  width: calc(100vw - 15%);
  left: calc(15.1% - 2px);
  height: auto;
`;

const PowderCaptionWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 64.5%;
  left: 74%;

  ${media.mobileMedium`
    top: 68.5%;
  `}

  ${media.mobileLarge`
    top: 74.4%;
  `}
`;

const SecondaryCaptionWrapper = styled.div`
  position: absolute;
  z-index: 9;
  left: 8%;
  top: 63.2%;

  ${media.mobileMedium`
    top: 66.6%;
  `}

  ${media.mobileLarge`
    top: 72.5%;
  `}
`;

export const PowderRoom = () => (
  <>
    <Desktop>
      <PowderWrapper>
        <HeroWrapper>
          <HeroAssetDesktopImage
            src={powderDesktop}
            alt='secondary bathroom and powder room'
          />
          <SecondaryTitle>
            Secondary
            <br /> Bathroom
          </SecondaryTitle>
          <SecondaryCaption>
            <p>
              Secondary Baths feature Calacatta Marble flooring, Savoy Brick Tile
              walls, and Waterworks fixtures &amp; hardware.
            </p>
          </SecondaryCaption>
          <PowderTitle>
            Powder
            <br /> Room
          </PowderTitle>
          <PowderCaption>
            <p>
              Powder Rooms feature Burlesque Marble walls and flooring as well
              as Waterworks Burnished Nickel fixtures &amp; hardware
            </p>
          </PowderCaption>
        </HeroWrapper>
        <GutterAddresses />
      </PowderWrapper>
    </Desktop>
    <Tablet>
      <PowderWrapperMobile>
        <HeroAssetTabletImage
          src={powderMobile}
          alt='secondary bathroom and powder room'
        />
        <SecondaryCaptionWrapper>
          <SecondaryTitleMobile>
            Secondary
            <br /> Bathroom
          </SecondaryTitleMobile>
          <SecondaryCaptionMobile>
            <p>
              Secondary Baths feature Calacatta Marble flooring, Savoy Brick Tile
              walls, and Waterworks fixtures &amp; hardware.
            </p>
          </SecondaryCaptionMobile>
        </SecondaryCaptionWrapper>
      </PowderWrapperMobile>
      <PowderWrapperMobile>
        <HeroAssetTabletImage
          src={secondPowderMobile}
          alt='secondary bathroom and powder room'
        />
        <PowderCaptionWrapper>
          <PowderTitleMobile>
            Powder
            <br /> Room
          </PowderTitleMobile>

          <PowderCaptionMobile>
            <p>
              Powder Rooms feature Burlesque Marble walls and flooring as well
              as Waterworks Burnished Nickel fixtures &amp; hardware
            </p>
          </PowderCaptionMobile>
        </PowderCaptionWrapper>
      </PowderWrapperMobile>
    </Tablet>
  </>
);
