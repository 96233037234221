import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import leftLobby from "../../images/LobbyImage.jpg";
import whiteBackgroundSketch from "../../images/WhiteBackgroundSketch.png";
import lobbySketch from "../../images/LobbySketch.png";
import { SectionWrapper } from "../common/Section";
import { Desktop, Tablet } from "../../components/common/media";

const DesignContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const Box = styled.div`
  display: flex;
  width: 100vw;
  height: 60vh;
  align-items: center;
  justify-content: center;
  color: black;

  ${media.desktop`
    width: 50vw;
    height: 100vh;
  `}
`;

const SketchBox = styled(Box)``;

const LobbyImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ImageSketchDesktop = styled.img`
  display: block;
  max-width: 100%;
  height: 100%;
`;

const ImageCaption = styled.figcaption`
  position: absolute;
  font: 10px "Calluna Italic";
  top: 92%;

  ${media.desktop`
    top: 63%;
    right: 21%;
    text-align: center;
    letter-spacing: 1px;

    hr {
      margin: 0 auto;
      margin-top: 38px;
      width: 58px;
    }
  `}

  ${media.desktopLarge`
    right: 23%;
  `}
`;

const ImageCaptionMobile = styled(ImageCaption)`
  top: 65%;

  ${media.mobileMedium`
    top: 70%;
  `}
`;

const ImageDescription = styled(ImageCaption)`
  display: none;

  ${media.desktop`
    display: block;
    top: 74%;
    right: auto;
    width: 330px;
    text-align: center;
    letter-spacing: 0.9px;
  `}
`;

const ImageDescriptionMobile = styled(ImageCaption)`
  display: block;
  top: 78%;
  right: auto;
  width: 300px;
  text-align: center;
  letter-spacing: 0.9px;

  ${media.mobileMedium`
    width: 330px;
    top: 80%;
  `}
`;

const MobileSketchContainer = styled(SectionWrapper)`
  height: 50vh;
  align-items: center;
  justify-content: center;
`;

const TabletSketch = styled.img`
  max-width: 100%;
`;

const MobileImageContainer = styled(SectionWrapper)`
  height: 80vh;
  width: 100vw;
`;

export const DesignerLobbyCustom = () => (
  <>
    <Desktop>
      <SectionWrapper>
        <DesignContainer>
          <Box>
            <LobbyImage src={leftLobby} />
          </Box>
          <SketchBox>
            <ImageSketchDesktop src={whiteBackgroundSketch} />
            <ImageCaption>
              Process Sketch from <br /> Kelly Behun Studio
              <hr />
            </ImageCaption>
            <ImageDescription>
              Interior designer Kelly Behun mixes Super White, Tundra Grey,
              French Vanilla, and Grigio Collemandina honed marbles to create
              the whimsical lobby floor. Custom plaster and millwork define the
              concierge desk area and package room. A ribbon of German Nickel at
              the base ties the lobby and seating area together.
            </ImageDescription>
          </SketchBox>
        </DesignContainer>
      </SectionWrapper>
    </Desktop>
    <Tablet>
      <MobileImageContainer>
        <TabletSketch src={leftLobby} />
      </MobileImageContainer>
      <MobileSketchContainer>
        <TabletSketch src={lobbySketch} />
        <ImageCaptionMobile>
          Process Sketch from <br /> Kelly Behun Studio
          <hr />
        </ImageCaptionMobile>
        <ImageDescriptionMobile>
          Interior designer Kelly Behun mixes Super White, Tundra Grey, French
          Vanilla, and Grigio Collemandina honed marbles to create the whimsical
          lobby floor. Custom plaster and millwork define the concierge desk
          area and package room. A ribbon of German Nickel at the base ties the
          lobby and seating area together.
        </ImageDescriptionMobile>
      </MobileSketchContainer>
    </Tablet>
  </>
);
