import React from "react";
import { Layout } from "../styles/Layout";
import SEO from "../components/common/SEO";
import theme from "../styles/theme";
import { ModalCarousel } from "../components/carousels/ModalCarousel";

const LegalPrivacy = () => {
  return (
    <Layout bgColor={theme.merino} pageTitle='Legal & Privacy'>
      <SEO title='Legal & Privacy' path='/legacy-privacy' />
      <ModalCarousel/>
    </Layout>
  )
}

export default LegalPrivacy
