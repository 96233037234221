import React from "react";
import { Layout } from "../styles/Layout";
import { useStaticQuery, graphql } from "gatsby";
import { Hero } from "../components/Hero";
import { InteriorLobbyModule } from "../components/Amenities/CustomLobbyModule";
import { AmenitiesCenterText } from "../components/TextModule/AmentiesCenterText";
import collectiveRooftopTerrace from "../images/CollectiveRooftopTerrace.jpg";
import collectiveRooftopTerraceMin from "../images/CollectiveRooftopTerrace-min.jpg";
import collectiveRooftopTerraceMobile from "../images/CollectiveRooftopTerrace-mobile.jpg";
import collectiveRooftopTerraceMobileMin from "../images/CollectiveRooftopTerrace-mobile-min.jpg";
import fitnessRoom from "../images/FitnessRoom.jpg";
import fitnessRoomMobile from "../images/Gym-Mobile-10-23.jpg";
import interiorLobby from "../images/amenities-lobby.jpg";
import interiorLobbyMobile from "../images/Lobby-Mobile-10-23.jpg";
import theme from "../styles/theme";
import SEO from "../components/common/SEO";

const amenitiesPageTitle = "Amenities";

const AmenitiesPage = () => {
  const data = useStaticQuery(AmenitiesQuery);
  const amenitiesPageContent = data.allContentfulAmenitiesPage.edges[0].node;
  const landingHeroCaption = amenitiesPageContent.landingHeroCaption;
  const centerTextDesktopTitle = amenitiesPageContent.centerTextDesktopTitle;
  const centerTextMobileTitle =
    amenitiesPageContent.centerTextMobileTitle.childMarkdownRemark.html;
  const centerTextDescription =
    amenitiesPageContent.centerTextDescription.centerTextDescription;
  const lastHeroCaption = amenitiesPageContent.lastHeroCaption;
  const title = amenitiesPageContent.title;
  const description = amenitiesPageContent.description;

  return (
    <Layout bgColor={theme.merino} pageTitle={amenitiesPageTitle}>
      <SEO title={title} description={description} path='/amenities' />
      <Hero
        heroImageDesktop={collectiveRooftopTerrace}
        heroImageDesktopMin={collectiveRooftopTerraceMin}
        leftCaption={landingHeroCaption}
        heroImageMobile={collectiveRooftopTerraceMobile}
        heroImageMobileMin={collectiveRooftopTerraceMobileMin}
        caption={landingHeroCaption}
      />
      <AmenitiesCenterText
        title={centerTextDesktopTitle}
        mobileTitle={centerTextMobileTitle}
        description={centerTextDescription}
      />
      <InteriorLobbyModule
        desktopImage={interiorLobby}
        mobileImage={interiorLobbyMobile}
      />
      <Hero
        heroImageDesktop={fitnessRoom}
        heroImageDesktopMin={fitnessRoom}
        leftCaption={lastHeroCaption}
        heroImageMobile={fitnessRoomMobile}
        heroImageMobileMin={fitnessRoomMobile}
        caption={lastHeroCaption}
      />
    </Layout>
  );
};

export default AmenitiesPage;

export const AmenitiesQuery = graphql`
  query GetAmenitiesPage {
    allContentfulAmenitiesPage {
      edges {
        node {
          title
          description
          landingHeroCaption
          centerTextDesktopTitle
          centerTextMobileTitle {
            childMarkdownRemark {
              html
            }
          }
          centerTextDescription {
            centerTextDescription
          }
          lastHeroCaption
        }
      }
    }
  }
`;
