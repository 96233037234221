import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { Layout } from "../styles/Layout";
import { useStaticQuery, graphql } from "gatsby";
import { BioSplit } from "../components/SplitModule/BioSplit";
import { IndexImageSplit } from "../components/SplitModule/IndexImageSplit";
import { Quote } from "../components/Quote";
import { ArchitectBio } from "../components/BioModule/ArchitectBio";
import { Hero } from "../components/Hero";
import { HeroLimitHeight } from "../components/HeroLimitHeight";
import { Splash } from "../components/Splash";
import SEO from "../components/common/SEO";
import heroImageDesktop from "../images/buildingaerial.jpg";
import heroImageMobile from "../images/Hero-1_Mobile.jpg";

import streetLevel from "../images/streetLevel.jpg";
import streetLevelMobile from "../images/streetlevelMobile.jpg";
import centralPark from "../images/CentralPark_withDescriptions.jpg";
import centralParkMobile from "../images/CentralParkMobileDescription.jpg";
import buildingSide from "../images/Guggenheim_Desktop.jpg";
import buildingSideMobile from "../images/Guggenheim_Mobile.jpg";
import serpentine from "../images/Serpentine-section-Desktop.jpg";
import serpentineMobile2 from "../images/SerpentineSection-mobile.jpg";
import theme from "../styles/theme";
import { fadeIn } from "../components/common/Header/Header";

const indexPageTitle = "Architecture";

const bioDescription =
  "Robert A.M. Stern Architects’ design for 1228 Madison Avenue marks a return to the grand, classical façades of yesteryear, updated with modern materials and amenities. At once sophisticated and understated, commanding and human-scaled, stylish and convivial, the building draws on a catalog of classical elements, arranged into a harmonious whole. It’s designed to rise up through the clear sun of a New York day, an intricate array of arches, string courses and windows, set against a background of subtly patterned brick, creating a composition of light and shadow as persuasive as a piece of music.";

const imageSplitCaption =
  "The Residents’ Terrace with spectacular Central Park and skyline views<br /> wraps around 1228's limestone-adorned crown";

const Scroll = createGlobalStyle`
  body {
    overflow-y: auto;
  }

  .page-title {
    opacity: 1;
    animation: ${fadeIn} 1s;
  }
`;

const IndexPage = (props) => {
  const [rehydrate, shouldRehydrate] = useState(false);
  const hasVideoProp = props.location.search.includes("v=1");
  const [splashState, setSplashState] = useState(
    hasVideoProp ? "hidden" : null
  );
  
  const [shouldScroll, setShouldScroll] = useState(
    splashState !== null && splashState !== "slide-down"
  );

  useEffect(() => {
    if (hasVideoProp && rehydrate === false) {
      /* shouldRehydrate(true);
       * setSplashState("server-refresh");
       * setShouldScroll(true);*/
    }
  }, [rehydrate, splashState]);

  const onLogoClick = () => {
    // if the user is slightly scrolled, the animation
    // will be janky, so start the user at the top
    window.scrollTo({ top: 0, behavior: "smooth" });

    // do not animate if already showing
    /* if (splashState === "slide-down" || !splashState) {
     *   return;
     * }

     * setSplashState("slide-down");
     * setShouldScroll(false);*/
  };

  const data = useStaticQuery(HomePageQuery);
  const homePageContent = data.allContentfulHomePage.edges[0].node;
  const title = homePageContent.title;
  const imageUrl = `${homePageContent.metaImage.file.url}?w=1280&q=90`;
  const description = homePageContent.description;
  const landingHeroCaption = homePageContent.landingHeroCaption;
  const imageBioDesktopTitle = homePageContent.imageBioDesktopTitle;
  const imageBioMobileTitle =
    homePageContent.imageBioMobileTitle.childMarkdownRemark.html;
  const imageBioDescription =
    homePageContent.imageBioDescription.childMarkdownRemark.html;
  const secondHeroCaption = homePageContent.secondHeroCaption;
  const thirdHeroCaption = homePageContent.thirdHeroCaption;

  return (
    <Layout
      bgColor={theme.merino}
      onLogoClick={onLogoClick}
      pageTitle={indexPageTitle}
    >
      <SEO title={title} description={description} imageUrl={imageUrl} />
      {<Scroll />}
      <Splash
          splashState={splashState}
          heroHeadline={homePageContent.heroHeadline.heroHeadline}
          heroImage={homePageContent.heroImage.file.url}
          heroImageMobile={homePageContent.heroImageMobile.file.url}
          onLearnMoreClick={() => {

          window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
          
        }}
      />
      <Hero
        indexPageTitle={indexPageTitle}
        heroImageDesktop={heroImageDesktop}
        heroImageDesktopMin={heroImageDesktop}
        heroImageMobile={heroImageMobile}
        heroImageMobileMin={heroImageMobile}
        leftCaption={landingHeroCaption}
        caption={landingHeroCaption}
      />
      <BioSplit
        desktopImage={buildingSide}
        description={imageBioDescription}
        desktopBuildingTitle={imageBioDesktopTitle}
        mobileImage={buildingSideMobile}
        mobileBio={imageBioDescription}
        isMobileTitle={true}
        mobileBuildingTitle={imageBioMobileTitle}
      />
      <Hero
        heroImageDesktop={streetLevel}
        heroImageDesktopMin={streetLevel}
        rightCaption={secondHeroCaption}
        heroImageMobile={streetLevelMobile}
        heroImageMobileMin={streetLevelMobile}
        caption={secondHeroCaption}
        isSquare={false}
      />
      <Quote />
      <HeroLimitHeight
        heroImageDesktop={centralPark}
        centerCaption={thirdHeroCaption}
        heroImageMobile={centralParkMobile}
        caption={thirdHeroCaption}
      />
      <IndexImageSplit
        desktopImage={serpentine}
        mobileImage={serpentineMobile2}
        light={true}
        imageCaption={imageSplitCaption}
      />
      <ArchitectBio bioDescription={bioDescription} />
    </Layout>
  );
};
export default IndexPage;

export const HomePageQuery = graphql`
  query GetHomePageContent {
    allContentfulHomePage {
      edges {
        node {
          title
          description
          metaImage {
            file {
              url
            }
          }
          landingHeroCaption
          imageBioDesktopTitle
          imageBioMobileTitle {
            childMarkdownRemark {
              html
            }
          }
          imageBioDescription {
            imageBioDescription
            childMarkdownRemark {
              html
            }
          }
          secondHeroCaption
          thirdHeroCaption
          heroHeadline {
            heroHeadline
          }
          heroImage {
            file {
              url
            }
          }
          heroImageMobile {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
