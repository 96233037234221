import { createGlobalStyle } from "styled-components"
import CallunaItalic from "../../static/fonts/Calluna-It-webfont.woff"
import CallunaItalic2 from "../../static/fonts/Calluna-It-webfont.woff2"
import CallunaLight from "../../static/fonts/Calluna-Light-webfont.woff"
import CallunaLight2 from "../../static/fonts/Calluna-Light-webfont.woff2"
import CallunaRegular from "../../static/fonts/Calluna-Regular-webfont.woff"
import CallunaRegular2 from "../../static/fonts/Calluna-Regular-webfont.woff2"
import CallunaSemibold from "../../static/fonts/Calluna-Semibold-webfont.woff"
import CallunaSemibold2 from "../../static/fonts/Calluna-Semibold-webfont.woff2"
import CallunaSemiboldItalic from "../../static/fonts/Calluna-SemiboldIt-webfont.woff"
import CallunaSemiboldItalic2 from "../../static/fonts/Calluna-SemiboldIt-webfont.woff2"
import SaolTextBook from "../../static/fonts/SaolText-Book.woff"
import SaolTextBook2 from "../../static/fonts/SaolText-Book.woff2"
import SaolTextBookItalic from "../../static/fonts/SaolText-BookItalic.woff"
import SaolTextBookItalic2 from "../../static/fonts/SaolText-BookItalic.woff2"
import SaolDisplayMedium from "../../static/fonts/SaolDisplay-Medium.woff"
import SaolDisplayMedium2 from "../../static/fonts/SaolDisplay-Medium.woff2"

export default createGlobalStyle`
    @font-face {
        font-family: 'Calluna Italic';
        src: local('Calluna Italic'), local('Calluna Italic'),
        url(${CallunaItalic2}) format('woff2'),
        url(${CallunaItalic}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Calluna Light';
        src: local('Calluna Light'), local('Calluna Light'),
        url(${CallunaLight2}) format('woff2'),
        url(${CallunaLight}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Calluna Regular';
        src: local('Calluna Regular'), local('Calluna Regular'),
        url(${CallunaRegular2}) format('woff2'),
        url(${CallunaRegular}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Calluna Semibold';
        src: local('Calluna Semibold'), local('Calluna Semibold'),
        url(${CallunaSemibold2}) format('woff2'),
        url(${CallunaSemibold}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Calluna Semibold Italic';
        src: local('Calluna Semibold Italic'), local('Calluna Semibold Italic'),
        url(${CallunaSemiboldItalic2}) format('woff2'),
        url(${CallunaSemiboldItalic}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Saol Text Book';
        src: local('Saol Text Book'), local('Saol Text Book'),
        url(${SaolTextBook2}) format('woff2'),
        url(${SaolTextBook}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Saol Text Book Italic';
        src: local('Saol Text Book Italic'), local('Saol Text Book Italic'),
        url(${SaolTextBookItalic2}) format('woff2'),
        url(${SaolTextBookItalic}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Saol Text Book Italic';
        src: local('Saol Text Book Italic'), local('Saol Text Book Italic'),
        url(${SaolTextBookItalic2}) format('woff2'),
        url(${SaolTextBookItalic}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Saol Display Medium';
        src: local('Saol Display Medium'), local('Saol Display Medium'),
        url(${SaolDisplayMedium2}) format('woff2'),
        url(${SaolDisplayMedium}) format('woff');
        font-style: normal;
    }
`
