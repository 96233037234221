import React, { useRef } from "react";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import media from "../../styles/media";
import masterBathOne from "../../images/MasterBath1.jpg";
import masterBathTwo from "../../images/MasterBath2.jpg";
import { DotsWrapper } from "./HeroCarousel";
import { SectionWrapper } from "../common/Section";

const SliderWrapper = styled(SectionWrapper)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 100%;
  max-height: 80vh;
  height: 80vh;
  padding-bottom: 250px;
  background-color: ${(props) => props.theme.offWhite};

  ${media.mobileLarge`
    padding-bottom: 150px;
  `}

  ${media.tablet`
    padding-bottom: 0;
    max-height: 100vh;
    height: 100vh;
  `}
`;

const SliderContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 0;
  height: 50vh;
  max-width: 100%;
  max-height: 50vh;
  width: 1357px;
  padding: 0 22px;
  margin-top: -25%;
  margin: 0 auto;

  ${media.tablet`
    max-height: 80vh;
    height: 80vh;
    padding: 0 20px 0 40px;
    width: 1204px;
    margin-top: 0;
  `}

  ${media.desktop`
    padding: 0 20px 0 60px;
  `}

  ${media.desktopMax`
    padding: 0 20px 0 70px;
    width: 1354px;
  `}

  .slick-slide {
    min-width: 0;

    img {
      height: 100%;
      width: 100%;
      max-width: 100vw;
      max-height: 50vh;
      min-width: 0;
      object-fit: cover;

      ${media.tablet`
        max-height: 80vh;
      `}
    }
  }

  .slick-list,
  .slider,
  .slick-track {
    height: 77vh;
    min-width: 0;
    max-height: 77vh;

    ${media.tablet`
      height: 80vh;
      max-height: 80vh;
    `}
  }
`;

const Slide = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  min-width: 0;
`;

export const DotsWrapperKitchen = styled(DotsWrapper)`
  bottom: auto;
  z-index: 3;

  li {
    .dot {
      bottom: 230px;
      color: black;
      border: 1px solid black;

      ${media.mobile`
        bottom: 330px;
      `}

      ${media.tablet`
        bottom: 30px;
      `}
    }

    &.slick-active .dot {
      background-color: black;
    }
  }
`;

const TextWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 48%;
    font: normal 15px/20px "Calluna Regular";
    margin-right: 20px;
    margin-top: -10%;
  `}

  ${media.desktop`
    width: 25%;
    margin-right: 30px;
    height: 200px;
    margin-top: -23%;
  `}

  ${media.desktopMedium`
    margin-top: -15%;
  `}

  ${media.desktopLarge`
    margin-left: 6px;
    width: 15%;
  `}
`;

const Border = styled.div`
  border-top: 1px solid black;
  width: 96px;
  margin: 10px 0;
`;

const CaptionStyles = css`
  display: flex;
  position: absolute;
  z-index: 5;
  font: normal 14px/19px "Calluna Regular";
`;

const ImageCaption = styled.div`
  ${CaptionStyles}
  display: flex;
  bottom: 1%;
  text-align: left;
  padding: 0 20px;
  flex-direction: column;
  z-index: 1;

  ${media.mobile`
     bottom: 10%;
  `}

  h1 {
    margin: 0;
  }

  ${media.tablet`
    display: none;
  `}
`;

const Title = styled.h1`
  font: normal 32px/33px "Saol Text Book";
`;

export function MasterBathCarousel() {
  const slideRef = useRef();

  const settings = {
    dots: true,
    draggable: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DotsWrapperKitchen>{dots}</DotsWrapperKitchen>,
    customPaging: () => <button className='dot' />,
  };

  return (
    <SliderWrapper>
      <SliderContainer>
        <Slider {...settings} ref={slideRef}>
          <Slide>
            <img src={masterBathOne} alt='view of master bathtub' />
          </Slide>
          <Slide>
            <img src={masterBathTwo} alt='view of vanity' />
          </Slide>
        </Slider>
      </SliderContainer>
      <ImageCaption>
        <Title>
          Master <br /> Baths
        </Title>
        <p>
          Indulge, repair, refresh. There’s no better way to rinse off the day
          than a long soak in a sumptuous, standalone tub. At 1228 Madison
          Avenue, they come with burnished nickel fixtures and sit on a creamy
          white Bianco Oro marble floor. It’s a hushed, tonal room, a place of
          respite and renewal, and the perfect way to soak for hours, a sybarite
          high above the city. A door on the eleventh floor leads outside to a
          private terrace: it’s like having your own spa, with sunlight waiting
          for you when you’re done.
        </p>
      </ImageCaption>
      <TextWrapper>
        <Title>
          Master <br /> Baths
        </Title>
        <Border />
        <p>
          Indulge, repair, refresh. There’s no better way to rinse off the day
          than a long soak in a sumptuous, standalone tub. At 1228 Madison
          Avenue, they come with burnished nickel fixtures and sit on a creamy
          white Bianco Oro marble floor. It’s a hushed, tonal room, a place of
          respite and renewal, and the perfect way to soak for hours, a sybarite
          high above the city. A door on the eleventh floor leads outside to a
          private terrace: it’s like having your own spa, with sunlight waiting
          for you when you’re done.
        </p>
        <Border />
      </TextWrapper>
    </SliderWrapper>
  );
}
