import React, { Component } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import {
  FormWrapper,
  FormTitle,
  FormInputBox,
  FieldSet,
  FirstOption,
  SecondOption,
  SubmitWrapper,
  SubmitButton,
  Required,
  Legend,
  Brokerage,
  FormLabel,
  ContactForm,
  FormSubtitle,
  FormTitleSmall,
  TextContainer,
  ArchitectGroup,
} from "./FormStyles";

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldErrors: {},
      FNAME: "",
      LNAME: "",
      EMAIL: "",
      PHONE: "",
      QUESTION1: "",
      QUESTION2: "",
      RADIORES: "",
      isBroker: false,

      serverState: {
        submitting: false,
        status: null,
      },
    };
  }

  _validate = () => {
    const { FNAME, LNAME, EMAIL, PHONE, QUESTION1, QUESTION2 } = this.state;
    const fieldErrors = {};
    let hasErrors = false;

    if (!EMAIL) {
      hasErrors = true;
      fieldErrors.EMAIL = true;
    }

    if (!FNAME) {
      hasErrors = true;
      fieldErrors.FNAME = true;
    }

    if (!LNAME) {
      hasErrors = true;
      fieldErrors.LNAME = true;
    }
    
    if (!PHONE) {
      hasErrors = true;
      fieldErrors.PHONE = true;
    }

    /* if (!QUESTION1) {
     *   hasErrors = true;
     *   fieldErrors.QUESTION1 = true;
     * }

     * if (!QUESTION2) {
     *   hasErrors = true;
     *   fieldErrors.QUESTION2 = true;
     * }*/

    this.setState({ fieldErrors });
    return !hasErrors;
  };

  _renderFieldError = (field) => {
    if (this.state.fieldErrors[field]) {
      return <p className='errorMsg'>Please enter a valid field.</p>;
    }
  };

  componentDidMount() {
    if (Object.keys(this.state.fieldErrors).length > 0) {
      this._validate();
    }
  }

  _handleSuccessResponse = (res) => {
    this.setState({ fieldErrors: {} });
    this.setState({
      EMAIL: "",
      FNAME: "",
      LNAME: "",
      QUESTION1: "",
      QUESTION2: "",
      RADIORES: "",
    });
  };

  _handleErrorResponse = (ok, msg) => {
    this.setState({
      serverState: {
        submitting: false,
        status: { ok, msg },
      },
    });
  };

  render() {
    const handleOnChange = (e) => {
      this.setState({
        [`${e.target.name}`]: e.target.value,
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (!this._validate()) {
        return;
      }
      this.props.displaySuccess();
      this.setState({
        serverState: {
          submitting: true,
        },
      });
      const {
        FNAME,
        LNAME,
        PHONE,
        QUESTION1,
        QUESTION2,
        RADIORES,
        YES,
      } = this.state;
      addToMailchimp(this.state.EMAIL, {
        FNAME,
        LNAME,
        PHONE,
        QUESTION1,
        QUESTION2,
        RADIORES,
        YES,
      })
        .then(this._handleSuccessResponse())
        .catch((r) => {
          this._handleErrorResponse(r.response.data.error);
        });
    };

    const toggleShowBroker = () => {
      this.setState({ isBroker: true });
    };

    const toggleHideBroker = () => {
      this.setState({ isBroker: false });
    };

    const {
      isBroker,
      FNAME,
      LNAME,
      EMAIL,
      PHONE,
      QUESTION1,
      QUESTION2,
      RADIORES,
      fieldErrors,
      serverState,
    } = this.state;

    const textStyles = isBroker ? "show-brokerage" : "hide-brokerage";
    return (
      <FormWrapper>
        <TextContainer>
          <FormTitle>13 Full-Floor, Duplex and Triplex Homes, <br />Overlooking Central Park</FormTitle>
          <FormTitleSmall>
            13 Full-Floor, Duplex and Triplex Homes, 
            <br /> Overlooking Central Park
          </FormTitleSmall>
          <FormSubtitle>Register Your Interest Below</FormSubtitle>
        </TextContainer>
        <ContactForm onSubmit={handleSubmit}>
          <FormInputBox>
            <FormLabel htmlFor='first'>First Name</FormLabel>
            <input
              type='text'
              name='FNAME'
              id='first'
              placeholder='First Name *'
              value={FNAME}
              onChange={handleOnChange}
              className={fieldErrors ? "error" : ""}
            />
          </FormInputBox>
          {this._renderFieldError("FNAME")}
          <FormInputBox>
            <FormLabel htmlFor='last'>Last Name</FormLabel>
            <input
              type='text'
              name='LNAME'
              id='last'
              placeholder='Last Name *'
              value={LNAME}
              onChange={handleOnChange}
              autoComplete='off'
              className={fieldErrors ? "error" : ""}
            />
          </FormInputBox>
          {this._renderFieldError("LNAME")}
          <FormInputBox>
            <FormLabel htmlFor='phone'>Phone</FormLabel>
            <input
              type='text'
              name='PHONE'
              placeholder='Phone *'
              autoComplete='off'
              id='phone'
              value={PHONE}
                onChange={handleOnChange}
                className={fieldErrors ? "error" : ""}
            />
          </FormInputBox>
          {this._renderFieldError("PHONE")}
          <FormInputBox>
            <FormLabel htmlFor='email'>Email</FormLabel>
            <input
              type='email'
              name='EMAIL'
              placeholder='Email *'
              onChange={handleOnChange}
              value={EMAIL}
              id='email'
              autoComplete='off'
              className={fieldErrors ? "error" : ""}
            />
          </FormInputBox>
          {this._renderFieldError("EMAIL")}
          <FormInputBox>
            <FormLabel htmlFor='referrer'>Referrer</FormLabel>
            <input
              type='text'
              name='QUESTION1'
              id='referrer'
              placeholder='How did you hear about us?'
              value={QUESTION1}
              onChange={handleOnChange}
              autoComplete='off'
              className={fieldErrors ? "error" : ""}
            />
          </FormInputBox>
          {this._renderFieldError("QUESTION1")}
          <FormInputBox css={`margin-bottom: 30px;`}>
            <FormLabel htmlFor='preference'>Referrer</FormLabel>
            <input
              type='text'
              name='QUESTION2'
              id='preference'
              placeholder='How do you prefer to be contacted?'
              value={QUESTION2}
              onChange={handleOnChange}
              autoComplete='off'
              className={fieldErrors ? "error" : ""}
            />
          </FormInputBox>
          {this._renderFieldError("QUESTION2")}
          
          <FieldSet>
            <Legend>Are you a broker?</Legend>
            <FirstOption>
              <input
                id='radio-1'
                className='radio-custom'
                type='radio'
                name='isBroker'
                value='Yes'
                onClick={toggleShowBroker}
              />
              <label htmlFor='radio-1' className='radio-custom-label'>
                Yes
              </label>
            </FirstOption>
            <SecondOption>
              <input
                id='radio-2'
                className='radio-custom'
                type='radio'
                name='isBroker'
                value='No'
                onClick={toggleHideBroker}
              />
              <label htmlFor='radio-2' className='radio-custom-label'>
                No
              </label>
            </SecondOption>
          </FieldSet>
          <Brokerage className={`${textStyles}`}>
            <label htmlFor='broker'>Which Brokerage Firm?</label>
            <input
              id='broker'
              type='text'
              name='RADIORES'
              value={RADIORES}
              onChange={handleOnChange}
            />
          </Brokerage>
          <SubmitWrapper>
            <SubmitButton type='submit' value='submit'>
              Submit
            </SubmitButton>
            <Required>*Required Fields</Required>
            {this.state.serverState.status && (
              <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
              </p>
            )}
          </SubmitWrapper>
          <ArchitectGroup>
            <a href="tel:212-828-2828">212.828.2828</a>
            <br /> <a href="mailto:info@1228madison.com">info@1228madison.com</a>
            <br /> <a target="_blank" href="https://www.google.com/maps/place/1228+Madison+Sales+Center/@40.782782,-73.9598537,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x5ea748f022ddbbe0!8m2!3d40.782782!4d-73.957665?authuser=3">
              1228 Madison Avenue
              <br /> New York, NY 10128
            </a>
          </ArchitectGroup>
        </ContactForm>
      </FormWrapper>
    );
  }
}
