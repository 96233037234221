import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import {
  BioWrapper,
  BioContainer,
  BioBox,
  TitleContainer,
  TitleText,
  Description,
  BioParagraph,
  BioLink,
} from "./Bio";

const ArchitectWrapper = styled(BioWrapper)`
  background-color: ${(props) => props.theme.offWhite};
`;

const ArchitectBioBox = styled(BioBox)``;

const ArchTitleContainer = styled(TitleContainer)`
  ${media.tablet`
    left: 3%;
  `}

  ${media.desktop`
    left: 30%;
  `}

  ${media.desktopMax`
    @media (min-height: 700px) {
      left: 30%;
    }

    @media (min-height: 1000px) {
      left: 39%;
    }
  `}
`;

const ArchitectTitle = styled(TitleText)`
  ${media.desktop`
    top: 24%;
    right: 18%;
  `}

  ${media.desktopLarge`
    @media (min-height: 700px) {
      font-size: 75px;
      line-height: 68px;
      letter-spacing: 2.8px;
    }
  `}

  @media (min-width: 1700px) {
    top: 0;
    font-size: 157px;
    letter-spacing: 3.9px;
    line-height: 144px;
    right: 15%;
  }
`;

const ArchitectDescription = styled(Description)``;

const ArchBio = styled(BioParagraph)`
  ${media.desktop`
    top: 3%;
    right: 3.5%;
  `}

  ${media.desktopLarge`
    top: 10%;
    right: 3.5%;
  `}

  ${media.desktopMax`
    top: 5%;
    left: 12.5%;
  `}
`;

const BioLinkContainer = styled.div`
  margin-top: 30px;
`;

export const ArchitectBio = ({ bioDescription }) => {
  return (
    <ArchitectWrapper>
      <BioContainer>
        <ArchitectBioBox>
          <ArchTitleContainer>
            <ArchitectTitle>
              Robert
              <br /> A.M.&nbsp;Stern <br /> Architects
            </ArchitectTitle>
          </ArchTitleContainer>
        </ArchitectBioBox>
        <ArchitectDescription>
          <ArchBio>
            {bioDescription}
            <br />
            <BioLinkContainer>
              <BioLink to='/architect/'>More about the Architect</BioLink>
            </BioLinkContainer>
          </ArchBio>
        </ArchitectDescription>
      </BioContainer>
    </ArchitectWrapper>
  );
};
